import { IMeta, IPagination, ISort } from "@delanobgt/admin-core/util/types";
import { CancelTokenSource } from "axios";
import { EAuthRoles } from "src/core/auth/entities";

import { ERetailWithdrawRequestSortField, IRetailWithdrawRequest, PRetailWithdrawRequestFilter } from "../entities";
import IRetailWithdrawRequestRepository from "../repository";
import IRetailWithdrawRequestUsecase from "../usecase";

export default class RetailWithdrawRequestUsecase implements IRetailWithdrawRequestUsecase<CancelTokenSource> {
  private retailWithdrawRequestRepo: IRetailWithdrawRequestRepository<CancelTokenSource>;

  constructor(retailWithdrawRequestRepo: IRetailWithdrawRequestRepository<CancelTokenSource>) {
    this.retailWithdrawRequestRepo = retailWithdrawRequestRepo;
  }

  async getRetailWithdrawRequest(
    role: EAuthRoles,
    pagination: IPagination,
    filter: PRetailWithdrawRequestFilter,
    sorts: ISort<ERetailWithdrawRequestSortField>[],
    signal?: CancelTokenSource,
  ): Promise<[IRetailWithdrawRequest[], IMeta]> {
    return await this.retailWithdrawRequestRepo.getRetailWithdrawRequest(role, pagination, filter, sorts, signal);
  }

  async getRetailWithdrawRequestById(id: number): Promise<IRetailWithdrawRequest> {
    return await this.retailWithdrawRequestRepo.getRetailWithdrawRequestById(id);
  }

  async createRetailWithdrawRequest(): Promise<void> {
    await this.retailWithdrawRequestRepo.createRetailWithdrawRequest();
  }

  async approveRetailWithdrawRequest(id: number, transaction_code: string): Promise<void> {
    await this.retailWithdrawRequestRepo.approveRetailWithdrawRequest(id, transaction_code);
  }

  async rejectRetailWithdrawRequest(id: number, rejected_reason: string): Promise<void> {
    await this.retailWithdrawRequestRepo.rejectRetailWithdrawRequest(id, rejected_reason);
  }
}
