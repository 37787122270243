import { produce } from "immer";
import { Reducer } from "redux";
import { IRetailStatistic } from "src/core/retailStatistic/entities";
import { ActionType, createAction, getType } from "typesafe-actions";

/*
 * state
 */
export interface IRetailStatisticState {
  retailStatistic: IRetailStatistic;
  loading: boolean;
  error: unknown;
}

const initialState: IRetailStatisticState = {
  retailStatistic: null,
  loading: true,
  error: null,
};

/*
 * action types
 */
const FETCH_REQUESTED = "retail-statistic/FETCH_REQUESTED";
const FETCH_SUCCEEDED = "retail-statistic/FETCH_SUCCEEDED";
const FETCH_FAILED = "retail-statistic/FETCH_FAILED";
const POLLING_START_REQUESTED = "retail-statistic/POLLING_START_REQUESTED";
const POLLING_STOP_REQUESTED = "retail-statistic/POLLING_STOP_REQUESTED";

/*
 * action creators
 */
export const actions = {
  fetchRequested: createAction(FETCH_REQUESTED)(),

  fetchSucceeded: createAction(FETCH_SUCCEEDED)<IRetailStatistic>(),

  fetchFailed: createAction(FETCH_FAILED)<unknown>(),

  pollingStartRequested: createAction(POLLING_START_REQUESTED)<{
    refreshDelay: number;
  }>(),

  pollingStopRequested: createAction(POLLING_STOP_REQUESTED)(),
};
export type TActions = ActionType<typeof actions>;

const reducer: Reducer<IRetailStatisticState, TActions> = (state = initialState, action) => {
  return produce<IRetailStatisticState>(state, (draft) => {
    switch (action.type) {
      case getType(actions.fetchRequested): {
        draft.loading = true;
        draft.error = null;
        break;
      }
      case getType(actions.fetchSucceeded): {
        const { payload } = action;
        draft.retailStatistic = payload;
        draft.loading = false;
        draft.error = null;
        break;
      }
      case getType(actions.fetchFailed): {
        const { payload } = action;
        draft.loading = false;
        draft.error = payload;
        break;
      }
    }
  });
};

export default reducer;
