export enum EAuthRoles {
  RETAIL = "retail",
  ADMIN = "admin",
}

export interface IAdminUser {
  id: number;
  role: {
    id: number;
    name: string;
  };
  username: string;
}
export type PAdminUser = Partial<IAdminUser>;
