import { IMeta, IPagination, ISort } from "@delanobgt/admin-core/util/types";
import { CancelTokenSource } from "axios";

import { ERetailShopSortField, IRetailShop, PRetailShopFilter } from "../entities";
import IRetailShopRepository from "../repository";
import IRetailShopUsecase, { ICreateRetailShopArgs } from "../usecase";

export default class RetailShopUsecase implements IRetailShopUsecase<CancelTokenSource> {
  private retailShopRepo: IRetailShopRepository<CancelTokenSource>;

  constructor(retailShopRepo: IRetailShopRepository<CancelTokenSource>) {
    this.retailShopRepo = retailShopRepo;
  }

  async getRetailShop(
    pagination: IPagination,
    filter: PRetailShopFilter,
    sorts: ISort<ERetailShopSortField>[],
    signal?: CancelTokenSource,
  ): Promise<[IRetailShop[], IMeta]> {
    const [retailShops, meta] = await this.retailShopRepo.getRetailShop(pagination, filter, sorts, signal);
    return [retailShops, meta];
  }

  async getRetailShopById(id: number): Promise<IRetailShop> {
    const retailShop = await this.retailShopRepo.getRetailShopById(id);
    return retailShop;
  }

  async createRetailShop(shop: ICreateRetailShopArgs, logoImage: unknown): Promise<void> {
    const formData = new FormData();
    formData.append("email", shop.email);
    formData.append("username", shop.username);
    formData.append("shop_name", shop.shop_name);
    formData.append("password", shop.password);
    formData.append("country_code", shop.country_code);
    formData.append("phone", shop.phone);
    formData.append("recipient", shop.recipient);
    formData.append("recipient_phone", shop.recipient_phone);
    formData.append("zip_code", shop.zip_code);
    formData.append("latitude", shop.latitude);
    formData.append("longitude", shop.longitude);
    formData.append("shop_description", shop.shop_description);
    formData.append("shop_admin_fee_percentage", String(shop.shop_admin_fee_percentage));
    formData.append("sub_district", shop.subdistrict);
    formData.append("logo_image", logoImage as Blob);

    await this.retailShopRepo.createRetailShop(formData);
  }
}
