import { IRetailProduct } from "./entities";

export function prepareRetailProduct(retailProduct: IRetailProduct) {
  retailProduct.description_image_urls = Boolean(retailProduct.description_image_path)
    ? retailProduct.description_image_path.split(",")
    : [];
  retailProduct.detail_image_urls = Boolean(retailProduct.detail_image_path)
    ? retailProduct.detail_image_path.split(",")
    : [];
  return retailProduct;
}
