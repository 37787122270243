import { IRoute } from "@delanobgt/admin-core/util/types";
import DashboardIcon from "@material-ui/icons/Dashboard";
import ReceiptIcon from "@material-ui/icons/Receipt";
import StoreIcon from "@material-ui/icons/Store";
import StyleIcon from "@material-ui/icons/Style";
import VerticalAlignTopIcon from "@material-ui/icons/VerticalAlignTop";
import _ from "lodash";
import React, { lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import useReactRouter from "use-react-router";

const Dashboard = lazy(() => import("src/pages/Dashboard/Dashboard"));
const RetailProduct = lazy(() => import("src/pages/RetailProduct/RetailProduct"));
const RetailProductCategory = lazy(() => import("src/pages/RetailProductCategory/RetailProductCategory"));
const RetailTransaction = lazy(() => import("src/pages/RetailTransaction"));
const RetailShop = lazy(() => import("src/pages/RetailShop/RetailShop"));
const RetailWithdrawRequest = lazy(() => import("src/pages/RetailWithdrawRequest/RetailWithdrawRequest"));

export type TAdminRouteNames =
  | "PREFIX_ADMIN"
  | "DASHBOARD"
  | "RETAIL_PRODUCT"
  | "RETAIL_PRODUCT_CATEGORY"
  | "RETAIL_SHOP"
  | "RETAIL_TRANSACTION"
  | "RETAIL_WITHDRAW_REQUEST";

export const adminRoutes: Record<TAdminRouteNames, IRoute> = {
  PREFIX_ADMIN: {
    Icon: null,
    path: "/admin",
    Page: null,
  },
  DASHBOARD: {
    Icon: DashboardIcon,
    path: "/dashboard",
    Page: Dashboard,
  },
  RETAIL_SHOP: {
    Icon: StoreIcon,
    path: "/retail-shop",
    Page: RetailShop,
  },
  RETAIL_PRODUCT: {
    Icon: StyleIcon,
    path: "/retail-product",
    Page: RetailProduct,
  },
  RETAIL_PRODUCT_CATEGORY: {
    Icon: StyleIcon,
    path: "/retail-product-category",
    Page: RetailProductCategory,
  },
  RETAIL_TRANSACTION: {
    Icon: ReceiptIcon,
    path: "/retail-transaction",
    Page: RetailTransaction,
  },
  RETAIL_WITHDRAW_REQUEST: {
    Icon: VerticalAlignTopIcon,
    path: "/retail-withdraw-request",
    Page: RetailWithdrawRequest,
  },
};

const AdminRoutes: React.FC = () => {
  const { match } = useReactRouter();

  return (
    <Switch>
      {_.values(adminRoutes)
        .filter((route) => Boolean(route.Page))
        .map((route) => {
          return <Route key={route.path} path={`${match.url}${route.path}`} component={route.Page} />;
        })}

      {/* wilcard routes */}
      <Route path={`${match.url}`}>
        <Redirect to={`${match.url}${adminRoutes.DASHBOARD.path}`} />
      </Route>
      <Route path={`${match.url}/*`}>
        <Redirect to={`${match.url}${adminRoutes.DASHBOARD.path}`} />
      </Route>
    </Switch>
  );
};

export default AdminRoutes;
