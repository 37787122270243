import { PRetailProductStock } from "../entities";
import IRetailProductStockRepository from "../repository";
import IRetailProductStockUsecase from "../usecase";

export default class RetailProductStockUsecase implements IRetailProductStockUsecase {
  private retailProductStockRepo: IRetailProductStockRepository;

  constructor(retailProductStockRepo: IRetailProductStockRepository) {
    this.retailProductStockRepo = retailProductStockRepo;
  }

  async createRetailProductStock(_retailProductStock: PRetailProductStock): Promise<void> {
    const retailProductStock = {
      ..._retailProductStock,
      price: Number(_retailProductStock.price),
      weight: Number(_retailProductStock.weight),
      stock: Number(_retailProductStock.stock),
      pre_order_stock: Number(_retailProductStock.pre_order_stock),
      retail_product_id: Number(_retailProductStock.retail_product_id),
      is_pre_order: Boolean(_retailProductStock.is_pre_order),
    };
    await this.retailProductStockRepo.createRetailProductStock(retailProductStock);
  }

  async updateRetailProductStock(_retailProductStock: PRetailProductStock): Promise<void> {
    const retailProductStock = {
      ..._retailProductStock,
      price: Number(_retailProductStock.price),
      weight: Number(_retailProductStock.weight),
      stock: Number(_retailProductStock.stock),
      pre_order_stock: Number(_retailProductStock.pre_order_stock),
      retail_product_id: Number(_retailProductStock.retail_product_id),
      is_pre_order: Boolean(_retailProductStock.is_pre_order),
    };
    await this.retailProductStockRepo.updateRetailProductStock(retailProductStock);
  }

  async deleteRetailProductStock(id: number): Promise<void> {
    await this.retailProductStockRepo.deleteRetailProductStock(id);
  }
}
