import { IMeta, IPagination, ISort } from "@delanobgt/admin-core/util/types";
import { CancelTokenSource } from "axios";
import { EAuthRoles } from "src/core/auth/entities";

import { ERetailTransactionSortField, IRetailTransaction, PRetailTransactionFilter } from "../entities";
import IRetailTransactionRepository from "../repository";
import IRetailTransactionUsecase from "../usecase";

export default class RetailTransactionUsecase implements IRetailTransactionUsecase<CancelTokenSource> {
  private retailTransactionRepo: IRetailTransactionRepository<CancelTokenSource>;

  constructor(retailTransactionRepo: IRetailTransactionRepository<CancelTokenSource>) {
    this.retailTransactionRepo = retailTransactionRepo;
  }

  async getRetailTransaction(
    role: EAuthRoles,
    pagination: IPagination,
    filter: PRetailTransactionFilter,
    sorts: ISort<ERetailTransactionSortField>[],
    signal?: CancelTokenSource,
  ): Promise<[IRetailTransaction[], IMeta]> {
    const [retailTransactions, meta] = await this.retailTransactionRepo.getRetailTransaction(
      role,
      pagination,
      filter,
      sorts,
      signal,
    );
    return [retailTransactions, meta];
  }

  async getRetailTransactionByCode(role: EAuthRoles, code: string): Promise<IRetailTransaction> {
    const retailTransaction = await this.retailTransactionRepo.getRetailTransactionByCode(role, code);
    return retailTransaction;
  }

  async sendRetailTransaction(code: string, courier_slug: string, tracking_code: string): Promise<void> {
    await this.retailTransactionRepo.sendRetailTransaction(code, courier_slug, tracking_code);
  }

  async contactComplain(code: string): Promise<void> {
    await this.retailTransactionRepo.contactComplain(code);
  }

  async proceedComplain(code: string, admin_note: string): Promise<void> {
    await this.retailTransactionRepo.proceedComplain(code, admin_note);
  }

  async refundComplain(code: string, admin_note: string): Promise<void> {
    await this.retailTransactionRepo.refundComplain(code, admin_note);
  }
}
