import { buildSort, goPromise } from "@delanobgt/admin-core/util/helpers";
import { IMeta, IPagination, ISort } from "@delanobgt/admin-core/util/types";
import { CancelTokenSource } from "axios";
import _ from "lodash";
import { EAuthRoles } from "src/core/auth/entities";
import { ApiClientFactory } from "@delanobgt/admin-core/util/types";

import { ERetailShopReviewSortField, IRetailShopReview, PRetailShopReviewFilter } from "../entities";
import IRetailShopReviewRepository from "../repository";

export default class RetailShopReviewRepository implements IRetailShopReviewRepository<CancelTokenSource> {
  private apiClientFactory: ApiClientFactory;

  // api routes
  private RETAIL_SHOP_REVIEW_ROUTE_FOR_ADMIN = "/admin/retail-shop-review";
  private RETAIL_SHOP_REVIEW_ROUTE_FOR_RETAIL = "/retail/retail-shop-review";
  private RETAIL_SHOP_REVIEW_ROUTE_BY_ROLE = {
    [EAuthRoles.ADMIN]: this.RETAIL_SHOP_REVIEW_ROUTE_FOR_ADMIN,
    [EAuthRoles.RETAIL]: this.RETAIL_SHOP_REVIEW_ROUTE_FOR_RETAIL,
  };

  constructor(apiClientFactory: ApiClientFactory) {
    this.apiClientFactory = apiClientFactory;
  }

  private buildFilter(filter: PRetailShopReviewFilter) {
    return filter;
  }

  private getSignalToken(signal: CancelTokenSource) {
    return _.get(signal, "token", null);
  }

  private getError(error: unknown) {
    return _.get(error, "response.data.errors", "Something went wrong!");
  }

  async getRetailShopReview(
    role: EAuthRoles,
    pagination: IPagination,
    filter: PRetailShopReviewFilter,
    sorts: ISort<ERetailShopReviewSortField>[],
    signal?: CancelTokenSource,
  ): Promise<[IRetailShopReview[], IMeta]> {
    const sort = buildSort(sorts);
    const params = _.pickBy(
      {
        ...pagination,
        ...this.buildFilter(filter),
      },
      (val) => val,
    );
    const [err, response] = await goPromise(
      this.apiClientFactory({ cancelToken: this.getSignalToken(signal) }).get(
        `${this.RETAIL_SHOP_REVIEW_ROUTE_BY_ROLE[role]}?sort=${sort}`,
        {
          params,
        },
      ),
    );
    if (err) {
      throw this.getError(err);
    }
    const { data, meta } = response.data;
    return [data, meta];
  }
}
