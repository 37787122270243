import CrossStorage from "@delanobgt/admin-core/apis/crossStorage";
import CenterItAll from "@delanobgt/admin-core/components/base/CenterItAll";
import { LocalStorageKeys } from "@delanobgt/admin-core/util/contants";
import { goPromise } from "@delanobgt/admin-core/util/helpers";
import { JWToken } from "@delanobgt/admin-core/util/types";
import jwtDecode from "jwt-decode";
import moment from "moment";
import React from "react";
import { useDispatch } from "react-redux";
import Fade from "react-reveal/Fade";
import { actions } from "src/redux/auth/ducks";
import styled from "styled-components";

import Interactors from "./core/Interactors";
import Router from "./Router/Router";

const SplashImage = styled.img`
  width: 225px;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
`;

const App: React.FC = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [splashEnd, setSplashEnd] = React.useState<boolean>(false);

  const initStore = React.useCallback(async () => {
    setLoading(true);

    const [err] = await goPromise(CrossStorage.getInstance().init());
    console.log("CrossStorage.getInstance().init() error: ", err);
    if (err) {
      dispatch(actions.defectBirth());
    } else if (!err) {
      const token = CrossStorage.getInstance().get(LocalStorageKeys.AUTH_TOKEN);

      // if token exist, then check token properties
      if (token) {
        const decoded: JWToken = jwtDecode(token);
        if (decoded.exp * 1000 < moment().valueOf()) {
          // token expired, signOut
          await goPromise(CrossStorage.getInstance().del(LocalStorageKeys.AUTH_TOKEN));
          dispatch(actions.signOut());
        } else {
          let err, res;
          // get current user info
          if (decoded.role === "retail") {
            [err, res] = await goPromise(Interactors.auth.getMeRetail());
            if (!err) dispatch(actions.getMeRetail(res));
          } else {
            [err, res] = await goPromise(Interactors.auth.getMeAdmin());
            if (!err) dispatch(actions.getMeAdmin(res));
          }
          if (err) {
            await goPromise(CrossStorage.getInstance().del(LocalStorageKeys.AUTH_TOKEN));
            dispatch(actions.signOut());
            return;
          }
          dispatch(actions.setToken(token));
        }
      }
    }

    setLoading(false);
  }, [dispatch]);

  // run initStore
  React.useEffect(() => {
    initStore();
    setTimeout(() => {
      setSplashEnd(true);
    }, 3000);
  }, [initStore, setSplashEnd]);

  return (
    <>
      <Fade collapse when={loading || !splashEnd}>
        <CenterItAll style={{ backgroundColor: "#ececec" }}>
          <SplashImage alt="" src={require("src/assets/images/splash/depatu-splash.gif")} />
        </CenterItAll>
      </Fade>

      {Boolean(!loading && splashEnd) && <Router />}
    </>
  );
};

export default App;
