import { PRetailUser } from "../entities";
import IRetailUserRepository from "../repository";
import IRetailUserUsecase from "../usecase";

export default class RetailUserUsecase implements IRetailUserUsecase {
  private retailUserRepo: IRetailUserRepository;

  constructor(retailUserRepo: IRetailUserRepository) {
    this.retailUserRepo = retailUserRepo;
  }

  async getRetailUserById(id: number): Promise<PRetailUser> {
    return await this.retailUserRepo.getRetailUserById(id);
  }
}
