import { IMeta, IPagination, ISort } from "@delanobgt/admin-core/util/types";
import { CancelTokenSource } from "axios";
import _ from "lodash";
import shallowDiff from "shallow-diff";

import {
  ERetailProductCategorySortField,
  IRetailProductCategory,
  PRetailProductCategory,
  PRetailProductCategoryFilter,
} from "../entities";
import IRetailProductCategoryRepository from "../repository";
import IRetailProductCategoryUsecase from "../usecase";

export default class RetailProductCategoryUsecase implements IRetailProductCategoryUsecase<CancelTokenSource> {
  private retailProductCategoryRepo: IRetailProductCategoryRepository<CancelTokenSource>;

  constructor(retailProductCategoryRepo: IRetailProductCategoryRepository<CancelTokenSource>) {
    this.retailProductCategoryRepo = retailProductCategoryRepo;
  }

  async getRetailProductCategory(
    pagination: IPagination,
    filter: PRetailProductCategoryFilter,
    sorts: ISort<ERetailProductCategorySortField>[],
    signal?: CancelTokenSource,
  ): Promise<[IRetailProductCategory[], IMeta]> {
    return await this.retailProductCategoryRepo.getRetailProductCategory(pagination, filter, sorts, signal);
  }

  async getRetailProductCategoryById(id: number): Promise<IRetailProductCategory> {
    return await this.retailProductCategoryRepo.getRetailProductCategoryById(id);
  }

  async createRetailProductCategory(retailProductCategory: PRetailProductCategory): Promise<void> {
    await this.retailProductCategoryRepo.createRetailProductCategory(retailProductCategory);
  }

  async updateRetailProductCategory(
    oldProductCategory: IRetailProductCategory,
    newProductCategory: PRetailProductCategory,
  ): Promise<void> {
    const diffProductCategory = _.pick(
      newProductCategory,
      shallowDiff(oldProductCategory, newProductCategory).updated,
    ) as PRetailProductCategory;
    await this.retailProductCategoryRepo.updateRetailProductCategory(oldProductCategory.id, diffProductCategory);
  }
}
