import { goPromise } from "@delanobgt/admin-core/util/helpers";
import { ApiClientFactory } from "@delanobgt/admin-core/util/types";
import { CancelTokenSource } from "axios";
import _ from "lodash";

import { IRetailStatistic, IRetailMonthlyRevenue } from "../entities";
import IRetailStatisticRepository from "../repository";

export default class RetailStatisticRepository implements IRetailStatisticRepository<CancelTokenSource> {
  private apiClientFactory: ApiClientFactory;

  // api routes
  private RETAIL_STATISTIC_ROUTE = "/retail/statistic";

  constructor(apiClientFactory: ApiClientFactory) {
    this.apiClientFactory = apiClientFactory;
  }

  private getSignalToken(signal: CancelTokenSource) {
    return _.get(signal, "token", null);
  }

  private getError(error: unknown) {
    return _.get(error, "response.data.errors", "Something went wrong.");
  }

  async getRetailStatistic(signal: CancelTokenSource): Promise<IRetailStatistic> {
    const [err, response] = await goPromise(
      this.apiClientFactory({ cancelToken: this.getSignalToken(signal) }).get(`${this.RETAIL_STATISTIC_ROUTE}`),
    );
    if (err) {
      throw this.getError(err);
    }
    return response.data;
  }

  async getRetailMonthlyRevenue(
    month: string,
    year: string,
    signal: CancelTokenSource,
  ): Promise<IRetailMonthlyRevenue> {
    const [err, response] = await goPromise(
      this.apiClientFactory({ cancelToken: this.getSignalToken(signal) }).get(
        `${this.RETAIL_STATISTIC_ROUTE}/revenue`,
        {
          params: {
            month,
            year,
          },
        },
      ),
    );
    if (err) {
      throw this.getError(err);
    }
    return response.data;
  }
}
