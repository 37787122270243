import CommonError from "@delanobgt/admin-core/components/error/CommonError";
import CircleProgress from "@delanobgt/admin-core/components/suspense/CircleProgress";
import Fetch from "@delanobgt/admin-core/components/suspense/Fetch";
import { IRoute } from "@delanobgt/admin-core/util/types";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import DashboardIcon from "@material-ui/icons/Dashboard";
import MoneyIcon from "@material-ui/icons/Money";
import PersonIcon from "@material-ui/icons/Person";
import ReceiptIcon from "@material-ui/icons/Receipt";
import StarHalfIcon from "@material-ui/icons/StarHalf";
import StyleIcon from "@material-ui/icons/Style";
import VerticalAlignTopIcon from "@material-ui/icons/VerticalAlignTop";
import _ from "lodash";
import React, { lazy } from "react";
import { useDispatch } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import Interactors from "src/core/Interactors";
import useAnalyzeSession from "src/hooks/useAnalyzeSession";
import { actions } from "src/redux/auth/ducks";
import useReactRouter from "use-react-router";

const RetailDashboard = lazy(() => import("src/pages/RetailDashboard"));
const RetailProduct = lazy(() => import("src/pages/RetailProduct"));
const RetailProfile = lazy(() => import("src/pages/RetailProfile"));
const RetailRevenue = lazy(() => import("src/pages/RetailRevenue"));
const RetailShop = lazy(() => import("src/pages/RetailShop/RetailShop"));
const RetailShopReview = lazy(() => import("src/pages/RetailShopReview"));
const RetailTransaction = lazy(() => import("src/pages/RetailTransaction"));
const RetailWalletMutation = lazy(() => import("src/pages/RetailWalletMutation"));
const RetailWithdrawRequest = lazy(() => import("src/pages/RetailWithdrawRequest"));

export type TRetailRouteNames =
  | "RETAIL_PREFIX"
  | "DASHBOARD"
  | "RETAIL_PRODUCT"
  | "RETAIL_PROFILE"
  | "RETAIL_TRANSACTION"
  | "RETAIL_REVENUE"
  | "RETAIL_SHOP_REVIEW"
  | "RETAIL_WALLET_MUTATION"
  | "RETAIL_WITHDRAW_REQUEST";

export const retailRoutes: Record<TRetailRouteNames, IRoute> = {
  RETAIL_PREFIX: {
    Icon: null,
    path: "/retail-shop",
    Page: RetailShop,
  },
  DASHBOARD: {
    Icon: DashboardIcon,
    path: "/dashboard",
    Page: RetailDashboard,
  },
  RETAIL_PRODUCT: {
    Icon: StyleIcon,
    path: "/retail-product",
    Page: RetailProduct,
  },
  RETAIL_PROFILE: {
    Icon: PersonIcon,
    path: "/retail-profile",
    Page: RetailProfile,
  },
  RETAIL_TRANSACTION: {
    Icon: ReceiptIcon,
    path: "/retail-transaction",
    Page: RetailTransaction,
  },
  RETAIL_REVENUE: {
    Icon: MoneyIcon,
    path: "/retail-revenue",
    Page: RetailRevenue,
  },
  RETAIL_SHOP_REVIEW: {
    Icon: StarHalfIcon,
    path: "/retail-shop-review",
    Page: RetailShopReview,
  },
  RETAIL_WALLET_MUTATION: {
    Icon: AccountBalanceWalletIcon,
    path: "/retail-wallet-mutation",
    Page: RetailWalletMutation,
  },
  RETAIL_WITHDRAW_REQUEST: {
    Icon: VerticalAlignTopIcon,
    path: "/retail-withdraw-request",
    Page: RetailWithdrawRequest,
  },
};

const RetailRoutes: React.FC = () => {
  const { isCurrentlyAdmin } = useAnalyzeSession();
  const { match } = useReactRouter<{ retail_shop_id: string }>();
  const dispatch = useDispatch();

  const fetch = React.useCallback(async () => {
    let res;
    if (isCurrentlyAdmin) {
      const shopRes = await Interactors.retailShop.getRetailShopById(Number(match.params.retail_shop_id));
      res = await Interactors.retailUser.getRetailUserById(shopRes.retail_user_id);
    } else {
      res = await Interactors.auth.getMeRetail();
    }
    dispatch(actions.getMeRetail(res));
    return true;
  }, [isCurrentlyAdmin, dispatch, match.params.retail_shop_id]);

  return (
    <Fetch<boolean>
      todo={fetch}
      renderLoading={() => <CircleProgress />}
      renderError={(error, retry) => <CommonError retry={retry} />}
      renderSuccess={() => (
        <Switch>
          {_.values(retailRoutes)
            .filter((route) => Boolean(route.Page))
            .map((route) => {
              return <Route key={route.path} path={`${match.url}${route.path}`} component={route.Page} />;
            })}

          {/* wilcard routes */}
          <Route path={`${match.url}`}>
            <Redirect to={`${match.url}${retailRoutes.DASHBOARD.path}`} />
          </Route>
          <Route path={`${match.url}/*`}>
            <Redirect to={`${match.url}${retailRoutes.DASHBOARD.path}`} />
          </Route>
        </Switch>
      )}
    />
  );
};

export default RetailRoutes;
