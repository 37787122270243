import { goPromise } from "@delanobgt/admin-core/util/helpers";
import { ApiClientFactory } from "@delanobgt/admin-core/util/types";
import _ from "lodash";
import { IRetailShop } from "src/core/retailShop/entities";
import { PRetailUser } from "src/core/retailUser/entities";

import { EAuthRoles, IAdminUser } from "../entities";
import IAuthRepository from "../repository";

export default class AuthRepository implements IAuthRepository {
  private celestineClientFactory: ApiClientFactory;
  private mercuryClientFactory: ApiClientFactory;

  // api routes
  private ADMIN_AUTH_ROUTE = "/admin/auth";
  private RETAIL_AUTH_ROUTE = "/retail/auth";
  private RETAIL_USER_ROUTE = "/retail/retail-user";
  private RETAIL_SHOP_ROUTE = "/retail/retail-shop";

  constructor(celestineClientFactory: ApiClientFactory, mercuryClientFactory: ApiClientFactory) {
    this.celestineClientFactory = celestineClientFactory;
    this.mercuryClientFactory = mercuryClientFactory;
  }

  private getError(error: unknown) {
    return _.get(error, "response.data.errors", "Something went wrong!");
  }

  async signInAdmin(credentials: { username: string; password: string }): Promise<{ token: string; user: IAdminUser }> {
    const [err, response] = await goPromise(
      this.celestineClientFactory().post(`${this.ADMIN_AUTH_ROUTE}/signin`, credentials),
    );
    if (err) {
      throw this.getError(err);
    }
    const authState = response.data;
    return authState;
  }

  async signInRetail(credentials: {
    username: string;
    password: string;
  }): Promise<{ token: string; retail_user: PRetailUser }> {
    const [err, response] = await goPromise(
      this.mercuryClientFactory().post(`${this.RETAIL_AUTH_ROUTE}/signin`, credentials),
    );
    if (err) {
      throw this.getError(err);
    }
    const authState = response.data;
    return authState;
  }

  async getMeAdmin(): Promise<IAdminUser> {
    const [err, response] = await goPromise(this.celestineClientFactory().get(`${this.ADMIN_AUTH_ROUTE}/me`));
    if (err) {
      throw this.getError(err);
    }
    const { user } = response.data;
    return user;
  }

  async getMeRetail(): Promise<PRetailUser> {
    const [err, response] = await goPromise(this.mercuryClientFactory().get(this.RETAIL_USER_ROUTE));
    if (err) {
      throw this.getError(err);
    }
    return response.data;
  }

  async getMeRetailShop(): Promise<IRetailShop> {
    const [err, response] = await goPromise(this.mercuryClientFactory().get(this.RETAIL_SHOP_ROUTE));
    if (err) {
      throw this.getError(err);
    }
    return response.data;
  }

  async forgetPasswordRetail(email: string): Promise<void> {
    const [err] = await goPromise(
      this.mercuryClientFactory().post(`${this.RETAIL_AUTH_ROUTE}/forget-password`, { email }),
    );
    if (err) {
      throw this.getError(err);
    }
  }

  async resetPasswordRetail(token: string, newPassword: string): Promise<void> {
    const [err] = await goPromise(
      this.mercuryClientFactory().post(`${this.RETAIL_AUTH_ROUTE}/reset-password`, {
        token,
        new_password: newPassword,
      }),
    );
    if (err) {
      throw this.getError(err);
    }
  }

  async validatePasswordRetail(token: string): Promise<void> {
    const [err] = await goPromise(this.mercuryClientFactory().get(`${this.RETAIL_AUTH_ROUTE}/reset-password/${token}`));
    if (err) {
      throw this.getError(err);
    }
  }

  async refreshToken(role: string): Promise<string> {
    let err, response;
    if (role === EAuthRoles.ADMIN) {
      [err, response] = await goPromise(this.celestineClientFactory().get(`${this.ADMIN_AUTH_ROUTE}/refresh`));
    } else {
      [err, response] = await goPromise(this.mercuryClientFactory().get(`${this.RETAIL_AUTH_ROUTE}/refresh`));
    }
    if (err) {
      throw this.getError(err);
    }
    const { token } = response.data;
    return token;
  }
}
