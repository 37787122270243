import { goPromise } from "@delanobgt/admin-core/util/helpers";
import _ from "lodash";
import { PRetailAddress, PRetailShop } from "src/core/retailShop/entities";
import { ApiClientFactory } from "@delanobgt/admin-core/util/types";

import { PRetailBank } from "../entities";
import IRetailProfileRepository, { IUpdateRetailGeneralArgs } from "../repository";

export default class RetailProfileRepository implements IRetailProfileRepository {
  private apiClientFactory: ApiClientFactory;

  // api routes
  private RETAIL_SHOP_ROUTE = "/retail/retail-shop";
  private RETAIL_USER_ROUTE = "/retail/retail-user";

  constructor(apiClientFactory: ApiClientFactory) {
    this.apiClientFactory = apiClientFactory;
  }

  private getError(error: unknown) {
    return _.get(error, "response.data.errors", "Something went wrong!");
  }

  async updateRetailGeneral(newInfo: IUpdateRetailGeneralArgs): Promise<void> {
    const [err] = await goPromise(this.apiClientFactory().patch(this.RETAIL_SHOP_ROUTE, newInfo));
    if (err) {
      throw this.getError(err);
    }
  }

  async updateRetailShopProfile(newShop: PRetailShop, address: PRetailAddress): Promise<void> {
    const [err] = await goPromise(
      this.apiClientFactory().patch(this.RETAIL_SHOP_ROUTE, {
        ...newShop,
        ...address,
      }),
    );
    if (err) {
      throw this.getError(err);
    }
  }

  async updateRetailBank(bank: PRetailBank, password: string): Promise<void> {
    const [err] = await goPromise(
      this.apiClientFactory().patch(this.RETAIL_SHOP_ROUTE, {
        bank_name: bank.name,
        bank_owner: bank.owner,
        bank_number: bank.number,
        password,
      }),
    );
    if (err) {
      throw this.getError(err);
    }
  }

  async updateRetailPassword(password: string, new_password: string): Promise<void> {
    const [err] = await goPromise(
      this.apiClientFactory().patch(this.RETAIL_USER_ROUTE, {
        password,
        new_password,
      }),
    );
    if (err) {
      throw this.getError(err);
    }
  }

  async updateRetailOpenCloseStore(is_closed: boolean): Promise<void> {
    const [err] = await goPromise(
      this.apiClientFactory().patch(this.RETAIL_SHOP_ROUTE, {
        is_closed,
      }),
    );
    if (err) {
      throw this.getError(err);
    }
  }

  async updateRetailStoreLogo(formData: FormData): Promise<void> {
    const [err] = await goPromise(this.apiClientFactory().patch(this.RETAIL_SHOP_ROUTE, formData));
    if (err) {
      throw this.getError(err);
    }
  }
}
