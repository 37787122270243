import { IMeta, IPagination, ISort } from "@delanobgt/admin-core/util/types";
import { CancelTokenSource } from "axios";
import { EAuthRoles } from "src/core/auth/entities";

import { ERetailWalletMutationSortField, IRetailWalletMutation, PRetailWalletMutationFilter } from "../entities";
import IRetailWalletMutationRepository from "../repository";
import IRetailWalletMutationUsecase from "../usecase";

export default class RetailWalletMutationUsecase implements IRetailWalletMutationUsecase<CancelTokenSource> {
  private retailWalletMutationRepo: IRetailWalletMutationRepository<CancelTokenSource>;

  constructor(retailWalletMutationRepo: IRetailWalletMutationRepository<CancelTokenSource>) {
    this.retailWalletMutationRepo = retailWalletMutationRepo;
  }

  async getRetailWalletMutation(
    role: EAuthRoles,
    pagination: IPagination,
    filter: PRetailWalletMutationFilter,
    sorts: ISort<ERetailWalletMutationSortField>[],
    signal?: CancelTokenSource,
  ): Promise<[IRetailWalletMutation[], IMeta]> {
    return await this.retailWalletMutationRepo.getRetailWalletMutation(role, pagination, filter, sorts, signal);
  }
}
