import { IMeta, IPagination, ISort } from "@delanobgt/admin-core/util/types";
import { CancelTokenSource } from "axios";
import { EAuthRoles } from "src/core/auth/entities";

import { ERetailShopReviewSortField, IRetailShopReview, PRetailShopReviewFilter } from "../entities";
import IRetailShopReviewRepository from "../repository";
import IRetailShopReviewUsecase from "../usecase";

export default class RetailShopReviewUsecase implements IRetailShopReviewUsecase<CancelTokenSource> {
  private retailShowReviewRepo: IRetailShopReviewRepository<CancelTokenSource>;

  constructor(retailShowReviewRepo: IRetailShopReviewRepository<CancelTokenSource>) {
    this.retailShowReviewRepo = retailShowReviewRepo;
  }

  async getRetailShopReview(
    role: EAuthRoles,
    pagination: IPagination,
    filter: PRetailShopReviewFilter,
    sorts: ISort<ERetailShopReviewSortField>[],
    signal?: CancelTokenSource,
  ): Promise<[IRetailShopReview[], IMeta]> {
    return await this.retailShowReviewRepo.getRetailShopReview(role, pagination, filter, sorts, signal);
  }
}
