import { EAuthRoles } from "src/core/auth/entities";

import { IAddressCity, IAddressDistrict, IAddressProvince, IAddressSubdistrict } from "../entities";
import IAddressRepository from "../repository";
import IAddressUsecase from "../usecase";

export default class AddressUsecase implements IAddressUsecase {
  private addressRepo: IAddressRepository;

  constructor(addressRepo: IAddressRepository) {
    this.addressRepo = addressRepo;
  }

  async getAddressProvince(
    role: EAuthRoles,
    filter: {
      province_name: string;
    },
  ): Promise<IAddressProvince[]> {
    return await this.addressRepo.getAddressProvince(role, filter);
  }

  getAddressCity = async (
    role: EAuthRoles,
    filter: {
      province_id: string;
      city_name: string;
    },
  ): Promise<IAddressCity[]> => {
    return await this.addressRepo.getAddressCity(role, filter);
  };

  getAddressDistrict = async (
    role: EAuthRoles,
    filter: {
      city_id: string;
      district_name: string;
    },
  ): Promise<IAddressDistrict[]> => {
    return await this.addressRepo.getAddressDistrict(role, filter);
  };

  getAddressSubdistrict = async (
    role: EAuthRoles,
    filter: {
      district_id: string;
      sub_district_name: string;
    },
  ): Promise<IAddressSubdistrict[]> => {
    return await this.addressRepo.getAddressSubdistrict(role, filter);
  };
}
