import DashboardIcon from "@material-ui/icons/Dashboard";
import { adminRoutes } from "src/Router/router/loggedInRoutes/AdminRoutes";
import { retailRoutes } from "src/Router/router/loggedInRoutes/RetailRoutes";

import { IMenu } from "./types";

export function makeRetailMenuList(retailShopId: string): IMenu[] {
  function prefixPath(path: string) {
    return `${retailRoutes.RETAIL_PREFIX.path}/${retailShopId}${path}`;
  }

  return [
    { title: "Dashboard", Icon: DashboardIcon, link: prefixPath("/dashboard") },
    {
      title: "My Profile",
      Icon: retailRoutes.RETAIL_PROFILE.Icon,
      link: prefixPath(retailRoutes.RETAIL_PROFILE.path),
    },
    {
      title: "Products",
      Icon: retailRoutes.RETAIL_PRODUCT.Icon,
      link: prefixPath(retailRoutes.RETAIL_PRODUCT.path),
    },
    {
      title: "Transactions",
      Icon: retailRoutes.RETAIL_TRANSACTION.Icon,
      link: prefixPath(retailRoutes.RETAIL_TRANSACTION.path),
    },
    {
      title: "Store Reviews",
      Icon: retailRoutes.RETAIL_SHOP_REVIEW.Icon,
      link: prefixPath(retailRoutes.RETAIL_SHOP_REVIEW.path),
    },
    {
      title: "Revenues",
      Icon: retailRoutes.RETAIL_REVENUE.Icon,
      link: prefixPath(retailRoutes.RETAIL_REVENUE.path),
    },
    {
      title: "Wallet Mutation",
      Icon: retailRoutes.RETAIL_WALLET_MUTATION.Icon,
      link: prefixPath(retailRoutes.RETAIL_WALLET_MUTATION.path),
    },
    {
      title: "Withdraw Request",
      Icon: retailRoutes.RETAIL_WITHDRAW_REQUEST.Icon,
      link: prefixPath(retailRoutes.RETAIL_WITHDRAW_REQUEST.path),
    },
  ];
}

export function makeAdminMenuList(): IMenu[] {
  function prefixPath(path: string) {
    return `${adminRoutes.PREFIX_ADMIN.path}${path}`;
  }

  return [
    {
      title: "Dashboard",
      Icon: adminRoutes.DASHBOARD.Icon,
      link: prefixPath(adminRoutes.DASHBOARD.path),
    },
    {
      title: "All Stores",
      Icon: adminRoutes.RETAIL_SHOP.Icon,
      link: prefixPath(adminRoutes.RETAIL_SHOP.path),
    },
    {
      title: "All Products",
      Icon: adminRoutes.RETAIL_PRODUCT.Icon,
      link: prefixPath(adminRoutes.RETAIL_PRODUCT.path),
    },
    {
      title: "All Transactions",
      Icon: adminRoutes.RETAIL_TRANSACTION.Icon,
      link: prefixPath(adminRoutes.RETAIL_TRANSACTION.path),
    },
    {
      title: "All Withdraw Requests",
      Icon: adminRoutes.RETAIL_WITHDRAW_REQUEST.Icon,
      link: prefixPath(adminRoutes.RETAIL_WITHDRAW_REQUEST.path),
    },
    {
      title: "Product Categories",
      Icon: adminRoutes.RETAIL_PRODUCT_CATEGORY.Icon,
      link: prefixPath(adminRoutes.RETAIL_PRODUCT_CATEGORY.path),
    },
  ];
}
