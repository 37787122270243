import { goPromise } from "@delanobgt/admin-core/util/helpers";
import _ from "lodash";
import { ApiClientFactory } from "@delanobgt/admin-core/util/types";

import { PRetailUser } from "../entities";
import IRetailUserRepository from "../repository";

export default class RetailUserRepository implements IRetailUserRepository {
  private apiClientFactory: ApiClientFactory;

  // api routes
  private RETAIL_USER_ROUTE = "/admin/retail-user";

  constructor(apiClientFactory: ApiClientFactory) {
    this.apiClientFactory = apiClientFactory;
  }

  private getError(error: unknown) {
    return _.get(error, "response.data.errors", "Something went wrong!");
  }

  async getRetailUserById(id: number): Promise<PRetailUser> {
    const [err, response] = await goPromise(this.apiClientFactory().get(`${this.RETAIL_USER_ROUTE}/${id}`));
    if (err) {
      throw this.getError(err);
    }
    return response.data;
  }
}
