import { buildSort, goPromise } from "@delanobgt/admin-core/util/helpers";
import { ApiClientFactory, IMeta, IPagination, ISort } from "@delanobgt/admin-core/util/types";
import { CancelTokenSource } from "axios";
import _ from "lodash";
import { EAuthRoles } from "src/core/auth/entities";
import { prepareRetailProduct } from "src/core/retailProduct/helpers";

import { ERetailTransactionSortField, IRetailTransaction, PRetailTransactionFilter } from "../entities";
import IRetailTransactionRepository from "../repository";

export default class RetailTransactionRepository implements IRetailTransactionRepository<CancelTokenSource> {
  private apiClientFactory: ApiClientFactory;

  // api routes
  private RETAIL_TRANSACTION_ROUTE_FOR_RETAIL = "/retail/retail-transaction";
  private RETAIL_TRANSACTION_ROUTE_FOR_ADMIN = "/admin/retail-transaction";
  private RETAIL_TRANSACTION_ROUTE_BY_ROLE = {
    [EAuthRoles.ADMIN]: this.RETAIL_TRANSACTION_ROUTE_FOR_ADMIN,
    [EAuthRoles.RETAIL]: this.RETAIL_TRANSACTION_ROUTE_FOR_RETAIL,
  };

  constructor(apiClientFactory: ApiClientFactory) {
    this.apiClientFactory = apiClientFactory;
  }

  private buildFilter(filter: PRetailTransactionFilter) {
    const created_at = filter.created_at_enabled ? `${filter.created_at_start},${filter.created_at_end}` : undefined;

    const clonedFilter = { ...filter };
    delete clonedFilter.created_at_enabled;
    delete clonedFilter.created_at_start;
    delete clonedFilter.created_at_end;

    return {
      ...clonedFilter,
      created_at,
    };
  }

  private getSignalToken(signal: CancelTokenSource) {
    return _.get(signal, "token", null);
  }

  private getError(error: unknown) {
    return _.get(error, "response.data.errors", "Something went wrong!");
  }

  async getRetailTransaction(
    role: EAuthRoles,
    pagination: IPagination,
    filter: PRetailTransactionFilter,
    sorts: ISort<ERetailTransactionSortField>[],
    signal?: CancelTokenSource,
  ): Promise<[IRetailTransaction[], IMeta]> {
    const sort = buildSort(sorts);
    const params = _.pickBy(
      {
        ...pagination,
        ...this.buildFilter(filter),
      },
      (val) => val,
    );
    const [err, response] = await goPromise(
      this.apiClientFactory({ cancelToken: this.getSignalToken(signal) }).get(
        `${this.RETAIL_TRANSACTION_ROUTE_BY_ROLE[role]}?sort=${sort}`,
        {
          params,
        },
      ),
    );
    if (err) {
      throw this.getError(err);
    }
    const { data, meta } = response.data;
    return [data, meta];
  }

  async getRetailTransactionByCode(role: EAuthRoles, code: string): Promise<IRetailTransaction> {
    const [err, response] = await goPromise(
      this.apiClientFactory().get(`${this.RETAIL_TRANSACTION_ROUTE_BY_ROLE[role]}/${code}/`),
    );
    if (err) {
      throw this.getError(err);
    }
    const retailTransaction: IRetailTransaction = response.data;
    if (retailTransaction.access_log) retailTransaction.accessLog = JSON.parse(retailTransaction.access_log);
    prepareRetailProduct(retailTransaction.retail_product_detail);
    return retailTransaction;
  }

  async sendRetailTransaction(code: string, courier_slug: string, tracking_code: string): Promise<void> {
    const [err] = await goPromise(
      this.apiClientFactory().post(`${this.RETAIL_TRANSACTION_ROUTE_FOR_RETAIL}/${code}/send`, {
        courier_slug,
        tracking_code,
      }),
    );
    if (err) {
      throw this.getError(err);
    }
  }

  async contactComplain(code: string): Promise<void> {
    const [err] = await goPromise(
      this.apiClientFactory().post(`${this.RETAIL_TRANSACTION_ROUTE_FOR_ADMIN}/${code}/contacted`),
    );
    if (err) {
      throw this.getError(err);
    }
  }

  async proceedComplain(code: string, admin_note: string): Promise<void> {
    const [err] = await goPromise(
      this.apiClientFactory().post(`${this.RETAIL_TRANSACTION_ROUTE_FOR_ADMIN}/${code}/proceed`, {
        admin_note,
      }),
    );
    if (err) {
      throw this.getError(err);
    }
  }

  async refundComplain(code: string, admin_note: string): Promise<void> {
    const [err] = await goPromise(
      this.apiClientFactory().post(`${this.RETAIL_TRANSACTION_ROUTE_FOR_ADMIN}/${code}/refund`, {
        admin_note,
      }),
    );
    if (err) {
      throw this.getError(err);
    }
  }
}
