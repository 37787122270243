import { buildSort, goPromise } from "@delanobgt/admin-core/util/helpers";
import { IMeta, IPagination, ISort } from "@delanobgt/admin-core/util/types";
import { CancelTokenSource } from "axios";
import _ from "lodash";
import { EAuthRoles } from "src/core/auth/entities";
import { ApiClientFactory } from "@delanobgt/admin-core/util/types";

import { ERetailProductSortField, IRetailProduct, PRetailProductFilter } from "../entities";
import { prepareRetailProduct } from "../helpers";
import IRetailProductRepository from "../repository";

export default class RetailProductRepository implements IRetailProductRepository<CancelTokenSource> {
  private apiClientFactory: ApiClientFactory;

  // api routes
  private RETAIL_PRODUCT_ROUTE_FOR_ADMIN = "/admin/retail-product";
  private RETAIL_PRODUCT_ROUTE_FOR_RETAIL = "/retail/retail-product";
  private RETAIL_PRODUCT_ROUTE_BY_ROLE = {
    [EAuthRoles.ADMIN]: this.RETAIL_PRODUCT_ROUTE_FOR_ADMIN,
    [EAuthRoles.RETAIL]: this.RETAIL_PRODUCT_ROUTE_FOR_RETAIL,
  };

  constructor(apiClientFactory: ApiClientFactory) {
    this.apiClientFactory = apiClientFactory;
  }

  private buildFilter(filter: PRetailProductFilter) {
    const release_date = filter.release_date_enabled
      ? `${filter.release_date_start},${filter.release_date_end}`
      : undefined;

    const limited_start_date = filter.limited_start_date_enabled
      ? `${filter.limited_start_date_start},${filter.limited_start_date_end}`
      : undefined;

    const pre_order_limited_start = filter.pre_order_limited_start_date_enabled
      ? `${filter.pre_order_limited_start_date_start},${filter.pre_order_limited_start_date_end}`
      : undefined;

    const clonedFilter = { ...filter };
    delete clonedFilter.release_date_enabled;
    delete clonedFilter.release_date_start;
    delete clonedFilter.release_date_end;
    delete clonedFilter.limited_start_date_enabled;
    delete clonedFilter.limited_start_date_start;
    delete clonedFilter.limited_start_date_end;
    delete clonedFilter.pre_order_limited_start_date_enabled;
    delete clonedFilter.pre_order_limited_start_date_start;
    delete clonedFilter.pre_order_limited_start_date_end;

    return _.pickBy(
      {
        ...clonedFilter,
        release_date,
        limited_start_date,
        pre_order_limited_start,
      },
      Boolean,
    );
  }

  private getSignalToken(signal: CancelTokenSource) {
    return _.get(signal, "token", null);
  }

  private getError(error: unknown) {
    return _.get(error, "response.data.errors", "Something went wrong!");
  }

  async getRetailProduct(
    role: EAuthRoles,
    pagination: IPagination,
    filter: PRetailProductFilter,
    sorts: ISort<ERetailProductSortField>[],
    signal?: CancelTokenSource,
  ): Promise<[IRetailProduct[], IMeta]> {
    const sort = buildSort(sorts);
    const params = _.pickBy(
      {
        ...pagination,
        ...this.buildFilter(filter),
      },
      (val) => val,
    );
    const [err, response] = await goPromise(
      this.apiClientFactory({ cancelToken: this.getSignalToken(signal) }).get(
        `${this.RETAIL_PRODUCT_ROUTE_BY_ROLE[role]}?sort=${sort}`,
        {
          params,
        },
      ),
    );
    if (err) {
      throw this.getError(err);
    }
    const { data, meta } = response.data;
    return [data, meta];
  }

  async getRetailProductById(role: EAuthRoles, id: number): Promise<IRetailProduct> {
    const [err, response] = await goPromise(
      this.apiClientFactory().get(`${this.RETAIL_PRODUCT_ROUTE_BY_ROLE[role]}/${id}`),
    );
    if (err) {
      throw this.getError(err);
    }
    const retailProduct: IRetailProduct = response.data;
    prepareRetailProduct(retailProduct);
    return retailProduct;
  }

  async createRetailProduct(formData: FormData): Promise<void> {
    const [err] = await goPromise(this.apiClientFactory().post(this.RETAIL_PRODUCT_ROUTE_FOR_RETAIL, formData));
    if (err) {
      throw this.getError(err);
    }
  }

  async updateRetailProduct(id: number, formData: FormData): Promise<void> {
    const [err] = await goPromise(
      this.apiClientFactory().patch(`${this.RETAIL_PRODUCT_ROUTE_FOR_RETAIL}/${id}`, formData),
    );
    if (err) {
      throw this.getError(err);
    }
  }

  async approveRetailProduct(id: number, retail_product_category_id: number, slug: string): Promise<void> {
    const [err] = await goPromise(
      this.apiClientFactory().post(`${this.RETAIL_PRODUCT_ROUTE_FOR_ADMIN}/${id}/approve`, {
        retail_product_category_id,
        slug,
      }),
    );
    if (err) {
      throw this.getError(err);
    }
  }

  async rejectRetailProduct(id: number, rejected_reason: string): Promise<void> {
    const [err] = await goPromise(
      this.apiClientFactory().post(`${this.RETAIL_PRODUCT_ROUTE_FOR_ADMIN}/${id}/reject`, {
        rejected_reason,
      }),
    );
    if (err) {
      throw this.getError(err);
    }
  }

  async deleteRetailProduct(id: number): Promise<void> {
    const [err] = await goPromise(this.apiClientFactory().delete(`${this.RETAIL_PRODUCT_ROUTE_FOR_RETAIL}/${id}`));
    if (err) {
      throw this.getError(err);
    }
  }
}
