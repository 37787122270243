import { IMeta, IPagination, ISort } from "@delanobgt/admin-core/util/types";
import { CancelTokenSource } from "axios";
import moment from "moment";
import { EAuthRoles } from "src/core/auth/entities";

import { ERetailProductSortField, IRetailProduct, PRetailProduct, PRetailProductFilter } from "../entities";
import IRetailProductRepository from "../repository";
import IRetailProductUsecase from "../usecase";

export default class RetailProductUsecase implements IRetailProductUsecase<CancelTokenSource> {
  private retailProductRepo: IRetailProductRepository<CancelTokenSource>;

  constructor(retailProductRepo: IRetailProductRepository<CancelTokenSource>) {
    this.retailProductRepo = retailProductRepo;
  }

  async getRetailProduct(
    role: EAuthRoles,
    pagination: IPagination,
    filter: PRetailProductFilter,
    sorts: ISort<ERetailProductSortField>[],
    signal?: CancelTokenSource,
  ): Promise<[IRetailProduct[], IMeta]> {
    return await this.retailProductRepo.getRetailProduct(role, pagination, filter, sorts, signal);
  }

  async getRetailProductById(role: EAuthRoles, id: number): Promise<IRetailProduct> {
    return await this.retailProductRepo.getRetailProductById(role, id);
  }

  async createRetailProduct(
    retailProduct: PRetailProduct,
    display_image: unknown,
    detail_images: unknown[],
    description_images: unknown[],
  ): Promise<void> {
    const formData = new FormData();

    formData.append("code", retailProduct.code);
    formData.append("name", retailProduct.name);
    formData.append("description", retailProduct.description);
    formData.append("is_active", String(retailProduct.is_active));
    formData.append("is_shipping_fee_charged", String(retailProduct.is_shipping_fee_charged));
    formData.append("release_date", moment(retailProduct.release_date).format());

    // is_limited
    formData.append("is_limited", String(retailProduct.is_limited));
    if (retailProduct.is_limited) {
      formData.append("limit_per_user", String(retailProduct.limit_per_user));
      formData.append("limited_start_date", moment(retailProduct.limited_start_date).format("YYYY-MM-DD"));
    }

    // is_pre_order_limited
    formData.append("is_pre_order_limited", String(retailProduct.is_pre_order_limited));
    if (retailProduct.is_pre_order_limited) {
      formData.append("pre_order_limit_per_user", String(retailProduct.pre_order_limit_per_user));
      formData.append(
        "pre_order_limited_start_date",
        moment(retailProduct.pre_order_limited_start_date).format("YYYY-MM-DD"),
      );
    }

    formData.append("display_image", display_image as Blob);
    for (const image of description_images) formData.append("description_images[]", image as Blob);
    for (const image of detail_images) formData.append("detail_images[]", image as Blob);

    await this.retailProductRepo.createRetailProduct(formData);
  }

  async updateRetailProduct(
    oldRetailProduct: PRetailProduct,
    newRetailProduct: PRetailProduct,
    display_image: unknown,
    initial_description_images: { image_path: string; deleted: boolean }[],
    description_images: unknown[],
    initial_detail_images: { image_path: string; deleted: boolean }[],
    detail_images: unknown[],
  ): Promise<void> {
    const formData = new FormData();

    if (oldRetailProduct.code !== newRetailProduct.code) formData.append("code", newRetailProduct.code);

    if (oldRetailProduct.name !== newRetailProduct.name) formData.append("name", newRetailProduct.name);

    formData.append("description", newRetailProduct.description);
    formData.append("is_active", String(newRetailProduct.is_active));
    formData.append("is_shipping_fee_charged", String(newRetailProduct.is_shipping_fee_charged));
    formData.append("release_date", moment(newRetailProduct.release_date).format());

    // is_limited
    formData.append("is_limited", String(newRetailProduct.is_limited));
    if (newRetailProduct.is_limited) {
      formData.append("limit_per_user", String(newRetailProduct.limit_per_user));
      formData.append("limited_start_date", moment(newRetailProduct.limited_start_date).format("YYYY-MM-DD"));
    }

    // is_pre_order_limited
    formData.append("is_pre_order_limited", String(newRetailProduct.is_pre_order_limited));
    if (newRetailProduct.is_pre_order_limited) {
      formData.append("pre_order_limit_per_user", String(newRetailProduct.pre_order_limit_per_user));
      formData.append(
        "pre_order_limited_start_date",
        moment(newRetailProduct.pre_order_limited_start_date).format("YYYY-MM-DD"),
      );
    }

    if (display_image) formData.append("new_display_image", display_image as Blob);

    if (description_images) {
      for (const image of description_images) formData.append("new_description_images[]", image as Blob);
    }
    const deleted_description_images = initial_description_images
      .filter((img) => img.deleted)
      .map((img) => img.image_path)
      .join(",");
    if (Boolean(deleted_description_images)) formData.append("deleted_description_images", deleted_description_images);

    if (detail_images) {
      for (const image of detail_images) formData.append("new_detail_images[]", image as Blob);
    }
    const deleted_detail_images = initial_detail_images
      .filter((img) => img.deleted)
      .map((img) => img.image_path)
      .join(",");
    if (Boolean(deleted_detail_images)) formData.append("deleted_detail_images", deleted_detail_images);

    await this.retailProductRepo.updateRetailProduct(oldRetailProduct.id, formData);
  }

  async approveRetailProduct(id: number, retail_product_category_id: number, slug: string): Promise<void> {
    await this.retailProductRepo.approveRetailProduct(id, retail_product_category_id, slug);
  }

  async rejectRetailProduct(id: number, rejected_reason: string): Promise<void> {
    await this.retailProductRepo.rejectRetailProduct(id, rejected_reason);
  }

  async deleteRetailProduct(id: number): Promise<void> {
    await this.retailProductRepo.deleteRetailProduct(id);
  }
}
