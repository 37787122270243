import CommonError from "@delanobgt/admin-core/components/error/CommonError";
import BreadcrumbLink from "@delanobgt/admin-core/components/link/BreadcrumbLink";
import CircleProgress from "@delanobgt/admin-core/components/suspense/CircleProgress";
import Fetch from "@delanobgt/admin-core/components/suspense/Fetch";
import { Breadcrumbs as MUIBreadcrumbs } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import HomeIcon from "@material-ui/icons/Home";
import _ from "lodash";
import React, { Suspense } from "react";
import { Breadcrumbs, BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { useDispatch } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import LazyLoaderIndicator from "src/components/misc/LazyLoaderIndicator";
import Interactors from "src/core/Interactors";
import useAnalyzeSession from "src/hooks/useAnalyzeSession";
import Nav from "src/pages/Nav";
import { actions } from "src/redux/auth/ducks";

import AdminRoutes, { adminRoutes } from "./loggedInRoutes/AdminRoutes";
import { generalRoutes } from "./loggedInRoutes/GeneralRoutes";
import RetailRoutes, { retailRoutes } from "./loggedInRoutes/RetailRoutes";

const useStyles = makeStyles((theme) => ({
  homeIconWrapper: { display: "flex", alignItems: "center" },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    zIndex: 5,
  },
}));

const LoggedInRoutes: React.FC = () => {
  const classes = useStyles({});

  const dispatch = useDispatch();
  const { isCurrentlyAdmin, isCurrentlyRetail, hasActiveRetailUser, tokenId } = useAnalyzeSession();

  // fetch retail user's data if logged in as `retail`
  const fetchRetail = React.useCallback(async () => {
    if (isCurrentlyRetail) {
      const getMeRetail = await Interactors.auth.getMeRetail();
      dispatch(actions.getMeRetail(getMeRetail));
    }
    return true;
  }, [dispatch, isCurrentlyRetail]);

  const dashboardUrl = isCurrentlyAdmin
    ? `${adminRoutes.PREFIX_ADMIN.path}${adminRoutes.DASHBOARD.path}`
    : `${retailRoutes.RETAIL_PREFIX.path}/${tokenId}${retailRoutes.DASHBOARD.path}`;

  const routeWeight = React.useCallback(
    (route: string): number => {
      const ROUTE_WEIGHTS: Record<string, number> = {
        [dashboardUrl]: -100,
      };
      return ROUTE_WEIGHTS[route] || route.length;
    },
    [dashboardUrl],
  );

  return (
    <Fetch<boolean>
      todo={fetchRetail}
      renderLoading={() => <CircleProgress />}
      renderError={(error, retry) => <CommonError retry={retry} />}
      renderSuccess={() => (
        <>
          <Nav />

          <main className={classes.content}>
            {/* breadcrumbs */}
            <Breadcrumbs
              compare={(a: { to: string }, b: { to: string }) => routeWeight(a.to) - routeWeight(b.to)}
              renameProps={{
                children: "label",
              }}
              container={MUIBreadcrumbs}
              containerProps={{}}
              item={BreadcrumbLink}
              finalItem={BreadcrumbLink}
              finalProps={{
                style: { color: "black" },
                disabled: true,
              }}
            />
            <br />

            <BreadcrumbsItem to={dashboardUrl}>
              <div className={classes.homeIconWrapper}>
                <HomeIcon />
              </div>
            </BreadcrumbsItem>

            <Suspense fallback={<LazyLoaderIndicator />}>
              <Switch>
                {/* retail shop routes */}
                <Route path={`${retailRoutes.RETAIL_PREFIX.path}/:retail_shop_id`}>
                  <RetailRoutes />
                </Route>

                {/* admin routes */}
                {isCurrentlyAdmin && (
                  <Route path={adminRoutes.PREFIX_ADMIN.path}>
                    <AdminRoutes />
                  </Route>
                )}

                {/* general routes */}
                {_.values(generalRoutes)
                  .filter((route) => Boolean(route.Page))
                  .map((route) => {
                    return <Route key={route.path} path={route.path} component={route.Page} />;
                  })}

                {/* wildcard route */}
                <Route path="*">
                  {Boolean(isCurrentlyAdmin || (isCurrentlyRetail && hasActiveRetailUser)) && (
                    <Redirect to={dashboardUrl} />
                  )}
                </Route>
              </Switch>
            </Suspense>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
          </main>
        </>
      )}
    />
  );
};

export default LoggedInRoutes;
