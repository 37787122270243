import CommonError from "@delanobgt/admin-core/components/error/CommonError";
import CircleProgress from "@delanobgt/admin-core/components/suspense/CircleProgress";
import Fetch from "@delanobgt/admin-core/components/suspense/Fetch";
import useIntervalRun from "@delanobgt/admin-core/hooks/useIntervalRun";
import { Popover } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";

interface INotification {
  // Icon: SvgIconComponent;
  Icon: string;
  title: React.ReactChild | string;
  subtitle: React.ReactChild | string;
  to?: string;
  onClick?: () => void;
}

interface IProps {
  anchorEl: Element;
  open: boolean;
  onClose: () => void;
}

const useStyles = makeStyles(() => ({
  container: {
    width: "25vw",
    minWidth: 250,
  },
}));

const Emp = styled.span`
  color: cornflowerblue;
`;

function NotificationPopover(props: IProps) {
  const { anchorEl, open, onClose } = props;
  const classes = useStyles({});
  const dispatch = useDispatch();
  // fetch
  const fetch = React.useCallback(async () => {
    return true;
  }, []);

  // do fetch every `refreshDelay` milliseconds
  const refreshDelay = 5000;
  const intervalRun = useIntervalRun(fetch, refreshDelay);
  const { setAlive: setFetchAlive } = intervalRun;

  // onModuleLoad
  const onModuleLoad = React.useCallback(() => {
    setFetchAlive(true);
  }, [setFetchAlive]);

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={open}
      onClose={onClose}
    >
      <div className={classes.container}>
        <Fetch<boolean>
          todo={fetch}
          onLoad={onModuleLoad}
          renderLoading={() => <CircleProgress />}
          renderError={(error, retry) => <CommonError retry={retry} />}
          renderSuccess={() => null}
        />
      </div>
    </Popover>
  );
}

export default NotificationPopover;
