import { applyMiddleware, combineReducers, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { reducer as formReducer } from "redux-form";
import { combineEpics, createEpicMiddleware } from "redux-observable";
import retailRevenueReducer from "src/redux/retailRevenue/ducks";
import retailRevenueEpics from "src/redux/retailRevenue/epics";
import retailWithdrawRequestReducer from "src/redux/retailWithdrawRequest/ducks";
import retailWithdrawRequestEpics from "src/redux/retailWithdrawRequest/epics";

import authReducer from "./auth/ducks";
import retailMonthlyRevenueReducer from "./retailMonthlyRevenue/ducks";
import retailMonthlyRevenueEpics from "./retailMonthlyRevenue/epics";
import retailProductReducer from "./retailProduct/ducks";
import retailProductEpics from "./retailProduct/epics";
import retailProductCategoryReducer from "./retailProductCategory/ducks";
import retailProductCategoryEpics from "./retailProductCategory/epics";
import retailShopReducer from "./retailShop/ducks";
import retailShopEpics from "./retailShop/epics";
import retailShopReviewReducer from "./retailShopReview/ducks";
import retailShopReviewEpics from "./retailShopReview/epics";
import retailStatisticReducer from "./retailStatistic/ducks";
import retailStatisticEpics from "./retailStatistic/epics";
import retailTransactionReducer from "./retailTransaction/ducks";
import retailTransactionEpics from "./retailTransaction/epics";
import retailWalletMutationReducer from "./retailWalletMutation/ducks";
import retailWalletMutationEpics from "./retailWalletMutation/epics";

export const rootReducer = combineReducers({
  auth: authReducer,
  form: formReducer,
  retailMonthlyRevenue: retailMonthlyRevenueReducer,
  retailProduct: retailProductReducer,
  retailProductCategory: retailProductCategoryReducer,
  retailRevenue: retailRevenueReducer,
  retailShop: retailShopReducer,
  retailShopReview: retailShopReviewReducer,
  retailStatistic: retailStatisticReducer,
  retailTransaction: retailTransactionReducer,
  retailWalletMutation: retailWalletMutationReducer,
  retailWithdrawRequest: retailWithdrawRequestReducer,
});

export const rootEpic = combineEpics(
  ...retailMonthlyRevenueEpics,
  ...retailProductEpics,
  ...retailProductCategoryEpics,
  ...retailRevenueEpics,
  ...retailShopEpics,
  ...retailShopReviewEpics,
  ...retailStatisticEpics,
  ...retailTransactionEpics,
  ...retailWalletMutationEpics,
  ...retailWithdrawRequestEpics,
);

export function configureStore() {
  const epicMiddleware = createEpicMiddleware();
  const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(epicMiddleware)));
  epicMiddleware.run(rootEpic);
  return store;
}

export type RootState = ReturnType<typeof rootReducer>;
