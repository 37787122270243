import { goPromise } from "@delanobgt/admin-core/util/helpers";
import _ from "lodash";
import { ApiClientFactory } from "@delanobgt/admin-core/util/types";

import { PRetailProductStock } from "../entities";
import IRetailProductStockRepository from "../repository";

export default class RetailProductStockRepository implements IRetailProductStockRepository {
  private apiClientFactory: ApiClientFactory;

  // api routes
  private RETAIL_PRODUCT_STOCK_ROUTE = "/retail/retail-product-stock";

  constructor(apiClientFactory: ApiClientFactory) {
    this.apiClientFactory = apiClientFactory;
  }

  private getError(error: unknown) {
    return _.get(error, "response.data.errors", "Something went wrong!");
  }

  async createRetailProductStock(retailProductStock: PRetailProductStock): Promise<void> {
    const [err] = await goPromise(
      this.apiClientFactory().post(`${this.RETAIL_PRODUCT_STOCK_ROUTE}`, retailProductStock),
    );
    if (err) {
      this.getError(err);
    }
  }

  async updateRetailProductStock(retailProductStock: PRetailProductStock): Promise<void> {
    const [err] = await goPromise(
      this.apiClientFactory().patch(`${this.RETAIL_PRODUCT_STOCK_ROUTE}/${retailProductStock.id}`, retailProductStock),
    );
    if (err) {
      this.getError(err);
    }
  }

  async deleteRetailProductStock(id: number): Promise<void> {
    const [err] = await goPromise(this.apiClientFactory().delete(`${this.RETAIL_PRODUCT_STOCK_ROUTE}/${id}`));
    if (err) {
      this.getError(err);
    }
  }
}
