import { IRoute } from "@delanobgt/admin-core/util/types";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { lazy } from "react";

const Logout = lazy(() => import("src/pages/Logout"));

export type TGeneralRouteNames = "LOGOUT";

export const generalRoutes: Record<TGeneralRouteNames, IRoute> = {
  LOGOUT: {
    Icon: ExitToAppIcon,
    path: "/logout",
    Page: Logout,
  },
};
