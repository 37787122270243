import { IRetailShop } from "src/core/retailShop/entities";
import { PRetailUser } from "src/core/retailUser/entities";

import { IAdminUser } from "../entities";
import IAuthRepository from "../repository";
import IAuthUsecase from "../usecase";

export default class AuthUsecase implements IAuthUsecase {
  private authRepo: IAuthRepository;

  constructor(authRepo: IAuthRepository) {
    this.authRepo = authRepo;
  }

  async signInAdmin(credentials: { username: string; password: string }): Promise<{ token: string; user: IAdminUser }> {
    return await this.authRepo.signInAdmin(credentials);
  }

  async signInRetail(credentials: {
    username: string;
    password: string;
  }): Promise<{ token: string; retail_user: PRetailUser }> {
    return await this.authRepo.signInRetail(credentials);
  }

  async getMeAdmin(): Promise<IAdminUser> {
    return await this.authRepo.getMeAdmin();
  }

  async getMeRetail(): Promise<PRetailUser> {
    return await this.authRepo.getMeRetail();
  }

  async getMeRetailShop(): Promise<IRetailShop> {
    return await this.authRepo.getMeRetailShop();
  }

  async forgetPasswordRetail(email: string): Promise<void> {
    await this.authRepo.forgetPasswordRetail(email);
  }

  async resetPasswordRetail(token: string, newPassword: string): Promise<void> {
    await this.authRepo.resetPasswordRetail(token, newPassword);
  }

  async validatePasswordRetail(token: string): Promise<void> {
    await this.authRepo.validatePasswordRetail(token);
  }

  async refreshToken(role: string): Promise<string> {
    return this.authRepo.refreshToken(role);
  }
}
