import { Box, Button, IconButton, Slide, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import React from "react";
import { useSelector } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { RootState } from "src/redux";

import useAnalyzeToken from "../hooks/useAnalyzeToken";
import LoggedInRoutes from "./router/LoggedInRoutes";
import LoginRoute from "./router/LoginRoutes";

const useStyles = makeStyles((theme) => ({
  footerRoot: {
    position: "fixed",
    bottom: 0,
    width: "100vw",
  },
  footer: {
    padding: theme.spacing(2),
    marginRight: theme.spacing(5),
    marginBottom: theme.spacing(3),
    background: "white",
    borderTopRightRadius: 7,
    borderBottomRightRadius: 7,
    display: "flex",
    justifyContent: "space-between",
  },
  text: {
    display: "flex",
    alignItems: "center",
  },
  helpRoot: {
    position: "fixed",
    bottom: "10vh",
  },
  helpButton: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    width: "3rem",
    height: "3rem",
  },
}));

const Router: React.FC = () => {
  const classes = useStyles({});
  const token = useSelector<RootState, string>((state) => state.auth.token);
  const { tokenExists } = useAnalyzeToken(token);
  const [showHelp, setShowHelp] = React.useState(false);

  return (
    <>
      <BrowserRouter>{!tokenExists ? <LoginRoute /> : <LoggedInRoutes />}</BrowserRouter>
      {!showHelp && (
        <div className={classes.helpRoot}>
          <Button
            className={classes.helpButton}
            color="primary"
            variant="contained"
            size="small"
            onClick={() => setShowHelp(true)}
          >
            Help
          </Button>
        </div>
      )}
      <Slide direction="right" in={showHelp} mountOnEnter unmountOnExit>
        <div className={classes.footerRoot}>
          <Box boxShadow={3} className={classes.footer}>
            <Typography variant="subtitle1" className={classes.text}>
              If you encounter any issue, please contact &nbsp;
              <img
                alt=""
                src="https://seeklogo.com/images/W/whatsapp-logo-33F6A82887-seeklogo.com.png"
                style={{ width: "1rem" }}
              />
              &nbsp; (+62) 811-6068-188
            </Typography>
            <IconButton onClick={() => setShowHelp(false)}>
              <CloseIcon />
            </IconButton>
          </Box>
        </div>
      </Slide>
    </>
  );
};

export default Router;
