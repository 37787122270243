import { PRetailAddress, PRetailShop } from "src/core/retailShop/entities";

import { PRetailBank } from "../entities";
import IRetailProfileRepository, { IUpdateRetailGeneralArgs } from "../repository";
import IRetailProfileUsecase from "../usecase";

export default class RetailProfileUsecase implements IRetailProfileUsecase {
  private retailProfileRepo: IRetailProfileRepository;

  constructor(retailProfileRepo: IRetailProfileRepository) {
    this.retailProfileRepo = retailProfileRepo;
  }

  async updateRetailGeneral(newInfo: IUpdateRetailGeneralArgs): Promise<void> {
    await this.retailProfileRepo.updateRetailGeneral(newInfo);
  }

  async updateRetailShopProfile(oldShop: PRetailShop, newShop: PRetailShop, address: PRetailAddress): Promise<void> {
    const diffShop = { ...newShop };
    if (oldShop.name === newShop.name) delete diffShop.name;
    await this.retailProfileRepo.updateRetailShopProfile(diffShop, address);
  }

  async updateRetailBank(bank: PRetailBank, password: string): Promise<void> {
    await this.retailProfileRepo.updateRetailBank(bank, password);
  }

  async updateRetailPassword(password: string, new_password: string): Promise<void> {
    await this.retailProfileRepo.updateRetailPassword(password, new_password);
  }

  async updateRetailOpenCloseStore(is_closed: boolean): Promise<void> {
    await this.retailProfileRepo.updateRetailOpenCloseStore(is_closed);
  }

  async updateRetailStoreLogo(new_logo_image: unknown): Promise<void> {
    const formData = new FormData();
    formData.append("new_logo_image", new_logo_image as Blob);
    await this.retailProfileRepo.updateRetailStoreLogo(formData);
  }
}
