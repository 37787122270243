import _ from "lodash";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "src/redux";

import useAnalyzeToken from "./useAnalyzeToken";
import { PRetailUser } from "src/core/retailUser/entities";
import { EAuthRoles } from "src/core/auth/entities";

function useAnalyzeSession() {
  const token = useSelector<RootState, string>((state) => state.auth.token);
  const { isRoleEquals, tokenId: _tokenId } = useAnalyzeToken(token);

  const activeRetailUser = useSelector<RootState, PRetailUser>((state) => state.auth.retail_user);
  const hasActiveRetailUser = React.useMemo(() => Boolean(activeRetailUser), [activeRetailUser]);
  const activeRetailShopId = React.useMemo(() => {
    return _.get(activeRetailUser, "retail_shop.id", null);
  }, [activeRetailUser]);

  const tokenId = React.useMemo(() => {
    return _tokenId;
  }, [_tokenId]);

  const getCurrentRole = React.useCallback(() => {
    if (isRoleEquals(EAuthRoles.ADMIN)) return EAuthRoles.ADMIN;
    if (isRoleEquals(EAuthRoles.RETAIL)) return EAuthRoles.RETAIL;
  }, [isRoleEquals]);

  const isCurrentlyAdmin = React.useMemo(() => isRoleEquals(EAuthRoles.ADMIN), [isRoleEquals]);
  const isCurrentlyRetail = React.useMemo(() => isRoleEquals(EAuthRoles.RETAIL), [isRoleEquals]);

  return React.useMemo(
    () => ({
      tokenId,
      isCurrentlyAdmin,
      isCurrentlyRetail,
      activeRetailUser,
      hasActiveRetailUser,
      activeRetailShopId,
      getCurrentRole,
    }),
    [
      tokenId,
      isCurrentlyAdmin,
      isCurrentlyRetail,
      activeRetailShopId,
      activeRetailUser,
      hasActiveRetailUser,
      getCurrentRole,
    ],
  );
}

export default useAnalyzeSession;
