import { ApiClientFactory } from "@delanobgt/admin-core/util/types";
import { CancelTokenSource } from "axios";
import celestineClientFactory from "src/apis/celestine";
import mercuryClientFactory from "src/apis/mercury";

import IAddressRepository from "./address/repository";
import AddressRepository from "./address/repository/repository";
import IAddressUsecase from "./address/usecase";
import AddressUsecase from "./address/usecase/usecase";
import IAuthRepository from "./auth/repository";
import AuthRepository from "./auth/repository/repository";
import IAuthUsecase from "./auth/usecase";
import AuthUsecase from "./auth/usecase/usecase";
import IRetailProductRepository from "./retailProduct/repository";
import RetailProductRepository from "./retailProduct/repository/repository";
import IRetailProductUsecase from "./retailProduct/usecase";
import RetailProductUsecase from "./retailProduct/usecase/usecase";
import IRetailProductCategoryRepository from "./retailProductCategory/repository";
import RetailProductCategoryRepository from "./retailProductCategory/repository/repository";
import IRetailProductCategoryUsecase from "./retailProductCategory/usecase";
import RetailProductCategoryUsecase from "./retailProductCategory/usecase/usecase";
import IRetailProductStockRepository from "./retailProductStock/repository";
import RetailProductStockRepository from "./retailProductStock/repository/repository";
import IRetailProductStockUsecase from "./retailProductStock/usecase";
import RetailProductStockUsecase from "./retailProductStock/usecase/usecase";
import IRetailProfileRepository from "./retailProfile/repository";
import RetailProfileRepository from "./retailProfile/repository/repository";
import IRetailProfileUsecase from "./retailProfile/usecase";
import RetailProfileUsecase from "./retailProfile/usecase/usecase";
import IRetailRevenueRepository from "./retailRevenue/repository";
import RetailRevenueRepository from "./retailRevenue/repository/repository";
import IRetailRevenueUsecase from "./retailRevenue/usecase";
import RetailRevenueUsecase from "./retailRevenue/usecase/usecase";
import IRetailShopRepository from "./retailShop/repository";
import RetailShopRepository from "./retailShop/repository/repository";
import IRetailShopUsecase from "./retailShop/usecase";
import RetailShopUsecase from "./retailShop/usecase/usecase";
import IRetailShopReviewRepository from "./retailShopReview/repository";
import RetailShopReviewRepository from "./retailShopReview/repository/repository";
import IRetailShopReviewUsecase from "./retailShopReview/usecase";
import RetailShopReviewUsecase from "./retailShopReview/usecase/usecase";
import IRetailStatisticRepository from "./retailStatistic/repository";
import RetailStatisticRepository from "./retailStatistic/repository/repository";
import IRetailStatisticUsecase from "./retailStatistic/usecase";
import IRetailTransactionRepository from "./retailTransaction/repository";
import RetailTransactionRepository from "./retailTransaction/repository/repository";
import IRetailTransactionUsecase from "./retailTransaction/usecase";
import RetailTransactionUsecase from "./retailTransaction/usecase/usecase";
import IRetailUserRepository from "./retailUser/repository";
import RetailUserRepository from "./retailUser/repository/repository";
import IRetailUserUsecase from "./retailUser/usecase";
import RetailUserUsecase from "./retailUser/usecase/usecase";
import IRetailWalletMutationRepository from "./retailWalletMutation/repository";
import RetailWalletMutationRepository from "./retailWalletMutation/repository/repository";
import IRetailWalletMutationUsecase from "./retailWalletMutation/usecase";
import RetailWalletMutationUsecase from "./retailWalletMutation/usecase/usecase";
import IRetailWithdrawRequestRepository from "./retailWithdrawRequest/repository";
import RetailWithdrawRequestRepository from "./retailWithdrawRequest/repository/repository";
import IRetailWithdrawRequestUsecase from "./retailWithdrawRequest/usecase";
import RetailWithdrawRequestUsecase from "./retailWithdrawRequest/usecase/usecase";
import RetailStatisticUsecase from "./retailStatistic/usecase/usecase";

interface IRepositories {
  addressRepository: IAddressRepository;
  authRepository: IAuthRepository;
  retailProductRepository: IRetailProductRepository<CancelTokenSource>;
  retailProductCategoryRepository: IRetailProductCategoryRepository<CancelTokenSource>;
  retailProductStockRepository: IRetailProductStockRepository;
  retailProfileRepository: IRetailProfileRepository;
  retailRevenueRepository: IRetailRevenueRepository<CancelTokenSource>;
  retailShopRepository: IRetailShopRepository<CancelTokenSource>;
  retailShopReviewRepository: IRetailShopReviewRepository<CancelTokenSource>;
  retailStatisticRepository: IRetailStatisticRepository<CancelTokenSource>;
  retailTransactionRepository: IRetailTransactionRepository<CancelTokenSource>;
  retailUserRepository: IRetailUserRepository;
  retailWalletMutationRepository: IRetailWalletMutationRepository<CancelTokenSource>;
  retailWithdrawRequestRepository: IRetailWithdrawRequestRepository<CancelTokenSource>;
}

function initRepositories(
  celestineClientFactory: ApiClientFactory,
  mercuryClientFactory: ApiClientFactory,
): IRepositories {
  return {
    addressRepository: new AddressRepository(celestineClientFactory),
    authRepository: new AuthRepository(celestineClientFactory, mercuryClientFactory),
    retailProductRepository: new RetailProductRepository(mercuryClientFactory),
    retailProductCategoryRepository: new RetailProductCategoryRepository(mercuryClientFactory),
    retailProductStockRepository: new RetailProductStockRepository(mercuryClientFactory),
    retailProfileRepository: new RetailProfileRepository(mercuryClientFactory),
    retailRevenueRepository: new RetailRevenueRepository(mercuryClientFactory),
    retailShopRepository: new RetailShopRepository(mercuryClientFactory),
    retailShopReviewRepository: new RetailShopReviewRepository(mercuryClientFactory),
    retailStatisticRepository: new RetailStatisticRepository(mercuryClientFactory),
    retailTransactionRepository: new RetailTransactionRepository(mercuryClientFactory),
    retailUserRepository: new RetailUserRepository(mercuryClientFactory),
    retailWalletMutationRepository: new RetailWalletMutationRepository(mercuryClientFactory),
    retailWithdrawRequestRepository: new RetailWithdrawRequestRepository(mercuryClientFactory),
  };
}

interface IUsecases {
  address: IAddressUsecase;
  auth: IAuthUsecase;
  retailProduct: IRetailProductUsecase<CancelTokenSource>;
  retailProductCategory: IRetailProductCategoryUsecase<CancelTokenSource>;
  retailProductStock: IRetailProductStockUsecase;
  retailProfile: IRetailProfileUsecase;
  retailRevenue: IRetailRevenueUsecase<CancelTokenSource>;
  retailShop: IRetailShopUsecase<CancelTokenSource>;
  retailShopReview: IRetailShopReviewUsecase<CancelTokenSource>;
  retailStatistic: IRetailStatisticUsecase<CancelTokenSource>;
  retailTransaction: IRetailTransactionUsecase<CancelTokenSource>;
  retailUser: IRetailUserUsecase;
  retailWalletMutation: IRetailWalletMutationUsecase<CancelTokenSource>;
  retailWithdrawRequest: IRetailWithdrawRequestUsecase<CancelTokenSource>;
}

function initUsecases(repos: IRepositories): IUsecases {
  return {
    address: new AddressUsecase(repos.addressRepository),
    auth: new AuthUsecase(repos.authRepository),
    retailProduct: new RetailProductUsecase(repos.retailProductRepository),
    retailProductCategory: new RetailProductCategoryUsecase(repos.retailProductCategoryRepository),
    retailProductStock: new RetailProductStockUsecase(repos.retailProductStockRepository),
    retailProfile: new RetailProfileUsecase(repos.retailProfileRepository),
    retailRevenue: new RetailRevenueUsecase(repos.retailRevenueRepository),
    retailShop: new RetailShopUsecase(repos.retailShopRepository),
    retailShopReview: new RetailShopReviewUsecase(repos.retailShopReviewRepository),
    retailStatistic: new RetailStatisticUsecase(repos.retailStatisticRepository),
    retailTransaction: new RetailTransactionUsecase(repos.retailTransactionRepository),
    retailUser: new RetailUserUsecase(repos.retailUserRepository),
    retailWalletMutation: new RetailWalletMutationUsecase(repos.retailWalletMutationRepository),
    retailWithdrawRequest: new RetailWithdrawRequestUsecase(repos.retailWithdrawRequestRepository),
  };
}

const repos = initRepositories(celestineClientFactory, mercuryClientFactory);
const Interactors: IUsecases = initUsecases(repos);

export default Interactors;
