import { buildSort, goPromise } from "@delanobgt/admin-core/util/helpers";
import { IMeta, IPagination, ISort } from "@delanobgt/admin-core/util/types";
import { CancelTokenSource } from "axios";
import _ from "lodash";
import { ApiClientFactory } from "@delanobgt/admin-core/util/types";

import { ERetailWalletMutationSortField, IRetailWalletMutation, PRetailWalletMutationFilter } from "../entities";
import IRetailWalletMutationRepository from "../repository";
import { EAuthRoles } from "src/core/auth/entities";

export default class RetailWalletMutationRepository implements IRetailWalletMutationRepository<CancelTokenSource> {
  private apiClientFactory: ApiClientFactory;

  // api routes
  private RETAIL_WALLET_MUTATION_ROUTE_FOR_RETAIL = "/retail/retail-wallet-mutation";
  private RETAIL_WALLET_MUTATION_ROUTE_FOR_ADMIN = "/admin/retail-wallet-mutation";
  private RETAIL_WALLET_MUTATION_ROUTE_BY_ROLE = {
    [EAuthRoles.ADMIN]: this.RETAIL_WALLET_MUTATION_ROUTE_FOR_ADMIN,
    [EAuthRoles.RETAIL]: this.RETAIL_WALLET_MUTATION_ROUTE_FOR_RETAIL,
  };

  constructor(apiClientFactory: ApiClientFactory) {
    this.apiClientFactory = apiClientFactory;
  }

  private buildFilter(filter: PRetailWalletMutationFilter) {
    const created_at = filter.created_at_enabled ? `${filter.created_at_start},${filter.created_at_end}` : undefined;

    const clonedFilter = { ...filter };
    delete clonedFilter.created_at_enabled;
    delete clonedFilter.created_at_start;
    delete clonedFilter.created_at_end;

    return {
      ...clonedFilter,
      created_at,
    };
  }

  private getSignalToken(signal: CancelTokenSource) {
    return _.get(signal, "token", null);
  }

  private getError(error: unknown) {
    return _.get(error, "response.data.errors", "Something went wrong!");
  }

  async getRetailWalletMutation(
    role: EAuthRoles,
    pagination: IPagination,
    filter: PRetailWalletMutationFilter,
    sorts: ISort<ERetailWalletMutationSortField>[],
    signal?: CancelTokenSource,
  ): Promise<[IRetailWalletMutation[], IMeta]> {
    const sort = buildSort(sorts);
    const params = _.pickBy(
      {
        ...pagination,
        ...this.buildFilter(filter),
      },
      (val) => val,
    );
    const [err, response] = await goPromise(
      this.apiClientFactory({ cancelToken: this.getSignalToken(signal) }).get(
        `${this.RETAIL_WALLET_MUTATION_ROUTE_BY_ROLE[role]}?sort=${sort}`,
        {
          params,
        },
      ),
    );
    if (err) {
      throw this.getError(err);
    }
    const { data, meta } = response.data;
    return [data, meta];
  }
}
