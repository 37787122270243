import { buildSort, goPromise } from "@delanobgt/admin-core/util/helpers";
import { IMeta, IPagination, ISort } from "@delanobgt/admin-core/util/types";
import { CancelTokenSource } from "axios";
import _ from "lodash";
import { ApiClientFactory } from "@delanobgt/admin-core/util/types";

import {
  ERetailProductCategorySortField,
  IRetailProductCategory,
  PRetailProductCategory,
  PRetailProductCategoryFilter,
} from "../entities";
import IRetailProductCategoryRepository from "../repository";

export default class RetailProductCategoryRepository implements IRetailProductCategoryRepository<CancelTokenSource> {
  private apiClientFactory: ApiClientFactory;

  // api routes
  private RETAIL_PRODUCT_CATEGORY_ROUTE = "/admin/retail-product-category";

  constructor(apiClientFactory: ApiClientFactory) {
    this.apiClientFactory = apiClientFactory;
  }

  private buildFilter(filter: PRetailProductCategoryFilter) {
    return filter;
  }

  private getSignalToken(signal: CancelTokenSource) {
    return _.get(signal, "token", null);
  }

  private getError(error: unknown) {
    return _.get(error, "response.data.errors", "Something went wrong!");
  }

  async getRetailProductCategory(
    pagination: IPagination,
    filter: PRetailProductCategoryFilter,
    sorts: ISort<ERetailProductCategorySortField>[],
    signal?: CancelTokenSource,
  ): Promise<[IRetailProductCategory[], IMeta]> {
    const sort = buildSort(sorts);
    const params = _.pickBy(
      {
        ...pagination,
        ...this.buildFilter(filter),
      },
      (val) => val,
    );
    const [err, response] = await goPromise(
      this.apiClientFactory({ cancelToken: this.getSignalToken(signal) }).get(
        `${this.RETAIL_PRODUCT_CATEGORY_ROUTE}?sort=${sort}`,
        {
          params,
        },
      ),
    );
    if (err) {
      throw this.getError(err);
    }
    const { data, meta } = response.data;
    return [data, meta];
  }

  async getRetailProductCategoryById(id: number): Promise<IRetailProductCategory> {
    const [err, response] = await goPromise(this.apiClientFactory().get(`${this.RETAIL_PRODUCT_CATEGORY_ROUTE}/${id}`));
    if (err) {
      throw this.getError(err);
    }
    return response.data;
  }

  async createRetailProductCategory(retailProductCategory: PRetailProductCategory): Promise<void> {
    const [err] = await goPromise(
      this.apiClientFactory().post(`${this.RETAIL_PRODUCT_CATEGORY_ROUTE}`, retailProductCategory),
    );
    if (err) {
      throw this.getError(err);
    }
  }

  async updateRetailProductCategory(id: number, retailProductCategory: IRetailProductCategory): Promise<void> {
    const [err] = await goPromise(
      this.apiClientFactory().patch(`${this.RETAIL_PRODUCT_CATEGORY_ROUTE}/${id}`, retailProductCategory),
    );
    if (err) {
      throw this.getError(err);
    }
  }
}
