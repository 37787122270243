import { CancelTokenSource } from "axios";

import { IRetailStatistic, IRetailMonthlyRevenue } from "../entities";
import IStatisticRepository from "../repository";
import IRetailStatisticUsecase from "../usecase";

export default class RetailStatisticUsecase implements IRetailStatisticUsecase<CancelTokenSource> {
  private statisticRepo: IStatisticRepository<CancelTokenSource>;

  constructor(statisticRepo: IStatisticRepository<CancelTokenSource>) {
    this.statisticRepo = statisticRepo;
  }

  async getRetailStatistic(signal: CancelTokenSource): Promise<IRetailStatistic> {
    return await this.statisticRepo.getRetailStatistic(signal);
  }

  async getRetailMonthlyRevenue(
    month: string,
    year: string,
    signal: CancelTokenSource,
  ): Promise<IRetailMonthlyRevenue> {
    return await this.statisticRepo.getRetailMonthlyRevenue(month, year, signal);
  }
}
