import { JWToken } from "@delanobgt/admin-core/util/types";
import jwtDecode from "jwt-decode";
import React from "react";

function useAnalyzeToken(token: string) {
  const tokenExists = React.useMemo(() => Boolean(token), [token]);

  const decodedToken: JWToken = React.useMemo(
    () => (token ? jwtDecode(token) : { iat: null, role: null, exp: null, id: null, username: null }),
    [token],
  );

  const tokenId = React.useMemo(() => {
    return decodedToken.id;
  }, [decodedToken.id]);

  const expMillis = React.useMemo(() => decodedToken.exp * 1000, [decodedToken.exp]);
  const iatMillis = React.useMemo(() => decodedToken.iat * 1000, [decodedToken.iat]);
  const isRoleEquals = React.useCallback(
    (role: string) => {
      return decodedToken.role === role;
    },
    [decodedToken.role],
  );

  return React.useMemo(
    () => ({
      tokenExists,
      tokenId,
      expMillis,
      iatMillis,
      isRoleEquals,
      role: decodedToken.role,
    }),
    [tokenExists, tokenId, expMillis, iatMillis, decodedToken.role, isRoleEquals],
  );
}

export default useAnalyzeToken;
