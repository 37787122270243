import { produce } from "immer";
import { Reducer } from "redux";
import { IAdminUser } from "src/core/auth/entities";
import { IRetailShop } from "src/core/retailShop/entities";
import { PRetailUser } from "src/core/retailUser/entities";
import { ActionType, createAction, getType } from "typesafe-actions";

/*
 * state
 */
export interface IAuthState {
  token: string;
  user?: IAdminUser;
  retail_user?: PRetailUser;
  defectBirth: boolean;
}

const initialState: IAuthState = {
  token: "",
  user: null,
  retail_user: null,
  defectBirth: false,
};

/*
 * action types
 */
const SIGN_IN = "auth/SIGN_IN";
const SIGN_OUT = "auth/SIGN_OUT";
const GET_ME_ADMIN = "auth/GET_ME_ADMIN";
const GET_ME_RETAIL = "auth/GET_ME_RETAIL";
const GET_ME_RETAIL_SHOP = "auth/GET_ME_RETAIL_SHOP";
const SET_TOKEN = "auth/SET_TOKEN";
const RESET_CURRENT_RETAIL_USER = "auth/RESET_CURRENT_RETAIL_USER";
const DEFECT_BIRTH = "auth/DEFECT_BIRTH";

/*
 * action creators
 */
export const actions = {
  signIn: createAction(SIGN_IN)<{
    token: string;
    user?: IAdminUser;
    retail_user?: PRetailUser;
  }>(),

  signOut: createAction(SIGN_OUT)(),

  getMeAdmin: createAction(GET_ME_ADMIN)<IAdminUser>(),

  getMeRetail: createAction(GET_ME_RETAIL)<PRetailUser>(),

  getMeRetailShop: createAction(GET_ME_RETAIL_SHOP)<IRetailShop>(),

  setToken: createAction(SET_TOKEN)<string>(),

  resetCurrentRetailUser: createAction(RESET_CURRENT_RETAIL_USER)(),

  defectBirth: createAction(DEFECT_BIRTH)(),
};
export type TActions = ActionType<typeof actions>;

const reducer: Reducer<IAuthState, TActions> = (state = initialState, action) => {
  return produce<IAuthState>(state, (draft) => {
    switch (action.type) {
      case getType(actions.signIn): {
        draft.token = action.payload.token;
        draft.retail_user = action.payload.retail_user;
        draft.user = action.payload.user;
        break;
      }
      case getType(actions.signOut): {
        draft.token = initialState.token;
        draft.retail_user = initialState.retail_user;
        draft.user = initialState.user;
        break;
      }
      case getType(actions.getMeAdmin): {
        draft.user = action.payload;
        break;
      }
      case getType(actions.getMeRetail): {
        draft.retail_user = {
          ...action.payload,
          retail_shop: action.payload.retail_shop ? action.payload.retail_shop : draft.retail_user.retail_shop,
        };
        break;
      }
      case getType(actions.getMeRetailShop): {
        draft.retail_user = {
          ...draft.retail_user,
          retail_shop: action.payload,
        };
        break;
      }
      case getType(actions.setToken): {
        draft.token = action.payload;
        break;
      }
      case getType(actions.resetCurrentRetailUser): {
        draft.retail_user = null;
        break;
      }
      case getType(actions.defectBirth): {
        draft.defectBirth = true;
        break;
      }
    }
  });
};

export default reducer;
