import Link from "@delanobgt/admin-core/components/link/BasicLink";
import { AppBar, Avatar, Chip, Divider, IconButton, Menu, MenuItem, Toolbar, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import MenuIcon from "@material-ui/icons/Menu";
import React from "react";
import { useDispatch } from "react-redux";
import useAnalyzeSession from "src/hooks/useAnalyzeSession";
import { actions as authActions } from "src/redux/auth/ducks";

import MyDrawer from "./nav/MyDrawer";
import NotificationPopover from "./nav/NotificationPopover";

const drawerWidth = 275;

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: "block",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    zIndex: 5,
  },
}));

export default function Nav() {
  const classes = useStyles({});
  const [profileAnchorEl, setProfileAnchorEl] = React.useState(null);
  const [notificationAnchorEl, setNotificationAnchorEl] = React.useState(null);
  const [drawerOpen, setDrawerOpen] = React.useState<boolean>(false);

  const isProfileMenuOpen = Boolean(profileAnchorEl);
  const isNotificationMenuOpen = Boolean(notificationAnchorEl);

  const handleProfileMenuOpen = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      setProfileAnchorEl(event.currentTarget);
    },
    [setProfileAnchorEl],
  );

  const handleNotificationMenuOpen = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      setNotificationAnchorEl(event.currentTarget);
    },
    [setNotificationAnchorEl],
  );

  const handleProfileMenuClose = React.useCallback(() => {
    setProfileAnchorEl(null);
  }, [setProfileAnchorEl]);

  const handleNotificationMenuClose = React.useCallback(() => {
    setNotificationAnchorEl(null);
  }, [setNotificationAnchorEl]);

  const dispatch = useDispatch();
  const { activeRetailUser, isCurrentlyAdmin } = useAnalyzeSession();

  return (
    <>
      <AppBar position="static" className={classes.appBar}>
        <Toolbar>
          <IconButton
            className={classes.menuButton}
            color="inherit"
            edge="start"
            onClick={() => setDrawerOpen(!drawerOpen)}
          >
            <MenuIcon />
          </IconButton>
          <img
            alt=""
            src={require("src/assets/images/icons/depatu03.svg")}
            style={{ height: 25, marginRight: "0.5rem" }}
          />
          <Typography className={classes.title} variant="h6" color="inherit" noWrap>
            Official Store
          </Typography>

          {Boolean(activeRetailUser) && isCurrentlyAdmin && (
            <>
              <Divider orientation="vertical" style={{ margin: "0rem 1rem", borderColor: "gray" }} />
              <Chip
                avatar={<Avatar alt={activeRetailUser.retail_shop.name} src={activeRetailUser.retail_shop.logo_path} />}
                label={activeRetailUser.retail_shop.name}
                color="secondary"
                onDelete={() => dispatch(authActions.resetCurrentRetailUser())}
              />
            </>
          )}
          <div className={classes.grow} />
          <div>
            {/* <IconButton color="inherit" onClick={handleNotificationMenuOpen}>
              <Badge badgeContent={0} color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton> */}
            <IconButton color="inherit" edge="end" onClick={handleProfileMenuOpen}>
              {isCurrentlyAdmin ? (
                <AccountCircleIcon />
              ) : (
                <Avatar alt="" src={activeRetailUser.retail_shop ? activeRetailUser.retail_shop.logo_path : null} />
              )}
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>

      <Menu
        anchorEl={profileAnchorEl}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        keepMounted
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={isProfileMenuOpen}
        onClose={handleProfileMenuClose}
      >
        {isCurrentlyAdmin && (
          <MenuItem onClick={handleProfileMenuClose}>
            Switch to&nbsp;
            <a href={process.env.REACT_APP_WARDITE_URL} style={{ textDecoration: "none", color: "#6495ed" }}>
              Marketplace
            </a>
          </MenuItem>
        )}
        <MenuItem onClick={handleProfileMenuClose}>
          <Link to="/logout">Logout</Link>
        </MenuItem>
      </Menu>

      <NotificationPopover
        anchorEl={notificationAnchorEl}
        open={isNotificationMenuOpen}
        onClose={handleNotificationMenuClose}
      />

      <MyDrawer drawerOpen={drawerOpen} setDrawerOpen={(open) => setDrawerOpen(open)} drawerWidth={drawerWidth} />
    </>
  );
}
