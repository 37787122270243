import { makeAxiosObservable } from "@delanobgt/admin-core/util/epics/AxiosObservable";
import { Epic } from "redux-observable";
import { concat, of, timer } from "rxjs";
import { catchError, debounceTime, filter, map, switchMap, takeUntil } from "rxjs/operators";
import Interactors from "src/core/Interactors";
import { RootState } from "src/redux";
import { isActionOf } from "typesafe-actions";

import { actions, TActions } from "./ducks";

export const pollingStatisticEpic: Epic<TActions, TActions, RootState> = (action$) =>
  action$.pipe(
    filter(isActionOf(actions.pollingStartRequested)),
    debounceTime(750),
    switchMap(({ payload }) =>
      timer(0, payload.refreshDelay).pipe(
        takeUntil(action$.pipe(filter(isActionOf(actions.pollingStopRequested)))),
        switchMap(() =>
          concat(
            makeAxiosObservable((signal) =>
              Interactors.retailStatistic.getRetailMonthlyRevenue(payload.month, payload.year, signal),
            ).pipe(
              map((retailStatistic) => actions.fetchSucceeded(retailStatistic)),
              catchError((error) => of(actions.fetchFailed(error))),
            ),
          ),
        ),
      ),
    ),
  );

const statisticEpics = [pollingStatisticEpic];

export default statisticEpics;
