import { Epic } from "redux-observable";
import { concat, of, timer } from "rxjs";
import { catchError, debounceTime, filter, map, switchMap, takeUntil } from "rxjs/operators";
import Interactors from "src/core/Interactors";
import { RootState } from "src/redux";
import { makeAxiosObservable } from "@delanobgt/admin-core/util/epics/AxiosObservable";
import { isActionOf } from "typesafe-actions";

import { actions, TActions } from "./ducks";

export const pollingRetailProductEpic: Epic<TActions, TActions, RootState> = (action$) =>
  action$.pipe(
    filter(isActionOf(actions.pollingStartRequested)),
    debounceTime(750),
    switchMap(({ payload }) =>
      timer(0, payload.refreshDelay).pipe(
        takeUntil(action$.pipe(filter(isActionOf(actions.pollingStopRequested)))),
        switchMap(() =>
          concat(
            of(actions.fetchRequested()),
            makeAxiosObservable((signal) =>
              Interactors.retailProduct.getRetailProduct(
                payload.role,
                payload.pagination,
                payload.filter,
                payload.sorts,
                signal,
              ),
            ).pipe(
              map(([data, meta]) => actions.fetchSucceeded({ data, meta })),
              catchError((error) => of(actions.fetchFailed(error))),
            ),
          ),
        ),
      ),
    ),
  );

const retailProductEpics = [pollingRetailProductEpic];

export default retailProductEpics;
