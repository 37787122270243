import { goPromise } from "@delanobgt/admin-core/util/helpers";
import _ from "lodash";
import { EAuthRoles } from "src/core/auth/entities";
import { ApiClientFactory } from "@delanobgt/admin-core/util/types";

import { IAddressCity, IAddressDistrict, IAddressProvince, IAddressSubdistrict } from "../entities";
import IAddressRepository from "../repository";

export default class AddressRepository implements IAddressRepository {
  private apiClientFactory: ApiClientFactory;

  // api routes
  private ADDRESS_BASE_ROUTE_FOR_ADMIN = "/admin";
  private ADDRESS_BASE_ROUTE_FOR_RETAIL = "/retail";
  private ADDRESS_BASE_ROUTE_BY_ROLE = {
    [EAuthRoles.ADMIN]: this.ADDRESS_BASE_ROUTE_FOR_ADMIN,
    [EAuthRoles.RETAIL]: this.ADDRESS_BASE_ROUTE_FOR_RETAIL,
  };

  constructor(apiClientFactory: ApiClientFactory) {
    this.apiClientFactory = apiClientFactory;
  }

  private getError(error: unknown) {
    return _.get(error, "response.data.errors", "Something went wrong!");
  }

  async getAddressProvince(
    role: EAuthRoles,
    filter: {
      province_name: string;
    },
  ): Promise<IAddressProvince[]> {
    const [err, response] = await goPromise(
      this.apiClientFactory().get(`${this.ADDRESS_BASE_ROUTE_BY_ROLE[role]}/shipping-address/province`, {
        params: { ...filter, limit: 100, offset: 0, sort: "name asc" },
      }),
    );
    if (err) {
      throw this.getError(err);
    }
    return response.data;
  }

  getAddressCity = async (
    role: EAuthRoles,
    filter: {
      province_id: string;
      city_name: string;
    },
  ): Promise<IAddressCity[]> => {
    const [err, response] = await goPromise(
      this.apiClientFactory().get(`${this.ADDRESS_BASE_ROUTE_BY_ROLE[role]}/shipping-address/city`, {
        params: { ...filter, limit: 100, offset: 0, sort: "name asc" },
      }),
    );
    if (err) {
      throw this.getError(err);
    }
    return response.data;
  };

  getAddressDistrict = async (
    role: EAuthRoles,
    filter: {
      city_id: string;
      district_name: string;
    },
  ): Promise<IAddressDistrict[]> => {
    const [err, response] = await goPromise(
      this.apiClientFactory().get(`${this.ADDRESS_BASE_ROUTE_BY_ROLE[role]}/shipping-address/district`, {
        params: { ...filter, limit: 100, offset: 0, sort: "name asc" },
      }),
    );
    if (err) {
      throw this.getError(err);
    }
    return response.data;
  };

  getAddressSubdistrict = async (
    role: EAuthRoles,
    filter: {
      district_id: string;
      sub_district_name: string;
    },
  ): Promise<IAddressSubdistrict[]> => {
    const [err, response] = await goPromise(
      this.apiClientFactory().get(`${this.ADDRESS_BASE_ROUTE_BY_ROLE[role]}/shipping-address/sub-district`, {
        params: { ...filter, limit: 100, offset: 0, sort: "name asc" },
      }),
    );
    if (err) {
      throw this.getError(err);
    }
    return response.data;
  };
}
