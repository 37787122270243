import { buildSort, goPromise } from "@delanobgt/admin-core/util/helpers";
import { IMeta, IPagination, ISort } from "@delanobgt/admin-core/util/types";
import { CancelTokenSource } from "axios";
import _ from "lodash";
import { ApiClientFactory } from "@delanobgt/admin-core/util/types";

import { ERetailShopSortField, IRetailShop, PRetailShopFilter } from "../entities";
import IRetailShopRepository from "../repository";

export default class RetailShopRepository implements IRetailShopRepository<CancelTokenSource> {
  private apiClientFactory: ApiClientFactory;

  // api routes
  private RETAIL_SHOP_ROUTE = "/admin/retail-shop";
  private REGISTER_RETAIL_SHOP_ROUTE = "/admin/auth/signup";

  constructor(apiClientFactory: ApiClientFactory) {
    this.apiClientFactory = apiClientFactory;
  }

  buildFilter(filter: PRetailShopFilter) {
    const created_at = filter.created_at_enabled ? `${filter.created_at_start},${filter.created_at_end}` : undefined;

    const clonedFilter = { ...filter };
    delete clonedFilter.created_at_enabled;
    delete clonedFilter.created_at_start;
    delete clonedFilter.created_at_end;

    return {
      ...clonedFilter,
      created_at,
    };
  }

  private getSignalToken(signal: CancelTokenSource) {
    return _.get(signal, "token", null);
  }

  private getError(error: unknown) {
    return _.get(error, "response.data.errors", "Something went wrong!");
  }

  async getRetailShop(
    pagination: IPagination,
    filter: PRetailShopFilter,
    sorts: ISort<ERetailShopSortField>[],
    signal?: CancelTokenSource,
  ): Promise<[IRetailShop[], IMeta]> {
    const sort = buildSort(sorts);
    const params = _.pickBy(
      {
        ...pagination,
        ...this.buildFilter(filter),
      },
      (val) => val,
    );
    const [err, response] = await goPromise(
      this.apiClientFactory({ cancelToken: this.getSignalToken(signal) }).get(
        `${this.RETAIL_SHOP_ROUTE}?sort=${sort}`,
        {
          params,
        },
      ),
    );
    if (err) {
      throw this.getError(err);
    }
    const { data, meta } = response.data;
    return [data, meta];
  }

  async getRetailShopById(id: number): Promise<IRetailShop> {
    const [err, response] = await goPromise(this.apiClientFactory().get(`${this.RETAIL_SHOP_ROUTE}/${id}`));
    if (err) {
      throw this.getError(err);
    }
    return response.data;
  }

  async createRetailShop(formData: FormData): Promise<void> {
    const [err] = await goPromise(this.apiClientFactory().post(`${this.REGISTER_RETAIL_SHOP_ROUTE}`, formData));
    if (err) {
      throw this.getError(err);
    }
  }
}
