import _ from "lodash";
import React, { Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import LazyLoaderIndicator from "src/components/misc/LazyLoaderIndicator";

import { loginRoutes } from "./loginRoutes/Routes";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  back: {
    width: "100vw",
    height: "100vh",
    backgroundColor: "#DF5354",
    position: "fixed",
    top: 0,
    left: 0,
    zIndex: -5,
  },
}));

const LoginRoutes: React.FC = () => {
  const styles = useStyles({});

  return (
    <>
      <div className={styles.back} />

      <Suspense fallback={<LazyLoaderIndicator />}>
        <Switch>
          {_.values(loginRoutes)
            .filter((route) => Boolean(route.Page))
            .map((route) => {
              return <Route key={route.path} path={route.path} component={route.Page} />;
            })}

          <Route path="*">
            <Redirect to={loginRoutes.LOGIN.path} />
          </Route>
        </Switch>
      </Suspense>
    </>
  );
};

export default LoginRoutes;
