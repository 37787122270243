import { IRoute } from "@delanobgt/admin-core/util/types";
import { lazy } from "react";

const ForgetPassword = lazy(() => import("src/pages/ForgetPassword"));
const Login = lazy(() => import("src/pages/Login/Login"));
const ResetPassword = lazy(() => import("src/pages/ResetPassword"));

export type TLoginRouteNames = "LOGIN" | "FORGET_PASSWORD" | "RESET_PASSWORD";

export const loginRoutes: Record<TLoginRouteNames, IRoute> = {
  LOGIN: {
    Icon: null,
    path: "/login",
    Page: Login,
  },
  FORGET_PASSWORD: {
    Icon: null,
    path: "/forget-password",
    Page: ForgetPassword,
  },
  RESET_PASSWORD: {
    Icon: null,
    path: "/reset-password/:token",
    Page: ResetPassword,
  },
};
