import { CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";

const useStyles = makeStyles({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    width: "100vw",
    position: "fixed",
    top: 0,
    left: 0,
    zIndex: 10,
    background: "rgba(0,0,0,0)",
  },
});

function LazyLoaderIndicator() {
  const classes = useStyles({});
  return (
    <div className={classes.root}>
      <CircularProgress />
    </div>
  );
}

export default LazyLoaderIndicator;
