import { Divider, Drawer, IconButton, Typography } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import _ from "lodash";
import React from "react";
import JwtTimer from "src/components/misc/JwtTimer";
import useAnalyzeSession from "src/hooks/useAnalyzeSession";
import { generalRoutes } from "src/Router/router/loggedInRoutes/GeneralRoutes";

import MenuList from "./myDrawer/MenuList";
import { makeAdminMenuList, makeRetailMenuList } from "./myDrawer/values";

const useStyles = makeStyles<Theme, IProps>((theme) => ({
  root: {
    display: "flex",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(5),
    zIndex: 5,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: (props) => props.drawerWidth,
  },
  drawer: {
    boxSizing: "border-box",
    width: (props) => props.drawerWidth,
    flexShrink: 0,
    zIndex: 5,
  },
  drawerPaper: {
    boxSizing: "border-box",
    width: (props) => props.drawerWidth,
  },
  drawerHeadline: {
    display: "flex",
    alignItems: "center",
    padding: "0.5rem 0",
  },
  bigText: {
    padding: "1rem",
    flex: 1,
  },
  menuGroupTitle: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
  },
}));

interface IProps {
  drawerOpen: boolean;
  drawerWidth: number;
  setDrawerOpen: (open: boolean) => void;
}

export default function TemporaryDrawer(props: IProps) {
  const classes = useStyles(props);

  const { drawerOpen, setDrawerOpen } = props;

  const { activeRetailShopId, activeRetailUser, isCurrentlyAdmin } = useAnalyzeSession();

  const retailMenuList = React.useMemo(() => makeRetailMenuList(activeRetailShopId), [activeRetailShopId]);

  const adminMenuList = React.useMemo(() => makeAdminMenuList(), []);

  return (
    <>
      <Drawer
        className={classes.drawer}
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="left"
        open={drawerOpen}
        keepMounted
        onClose={() => setDrawerOpen(false)}
      >
        <div className={classes.drawerHeadline} onKeyDown={() => setDrawerOpen(false)}>
          <IconButton onClick={() => setDrawerOpen(false)} style={{ marginLeft: "0.5rem", marginRight: "0.5rem" }}>
            <MenuIcon />
          </IconButton>
          <img
            alt=""
            src={require("src/assets/images/icons/depatu.png")}
            style={{ height: 25, marginRight: "0.5rem" }}
          />
          <Typography variant="h6" color="inherit" noWrap>
            Official Store
          </Typography>
        </div>
        <Divider />
        <JwtTimer />
        <Divider />
        {Boolean(activeRetailUser) && (
          <>
            <Typography variant="body2" className={classes.menuGroupTitle}>
              <strong>{_.get(activeRetailUser, "retail_shop.name", "-")}</strong>
            </Typography>
            <MenuList menuList={retailMenuList} setDrawerOpen={setDrawerOpen} />
            <Divider />
          </>
        )}
        {Boolean(isCurrentlyAdmin) && (
          <>
            <Typography variant="body2" className={classes.menuGroupTitle}>
              <strong>Admin</strong>
            </Typography>
            <MenuList menuList={adminMenuList} setDrawerOpen={setDrawerOpen} />
          </>
        )}
        <MenuList
          menuList={[
            {
              title: "Logout",
              Icon: generalRoutes.LOGOUT.Icon,
              link: generalRoutes.LOGOUT.path,
            },
          ]}
          setDrawerOpen={setDrawerOpen}
        />
      </Drawer>
    </>
  );
}
