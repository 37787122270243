import BasicLink from "@delanobgt/admin-core/components/link/BasicLink";
import { Collapse, List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import _ from "lodash";
import React from "react";
import useReactRouter from "use-react-router";

import { IMenu } from "./types";

interface IProps {
  menuList: IMenu[];
  setDrawerOpen: (open: boolean) => void;
}

export default function MenuList({ setDrawerOpen, menuList }: IProps) {
  const { location } = useReactRouter();
  const PADDING_MULTIPLIER = 1.5;

  const [navState, setNavState] = React.useState<Record<string, boolean>>({});

  const toggleCollapse = React.useCallback(
    (stateName) => {
      const depth = Number(stateName.split("#")[1]);
      setNavState({
        ..._.mapValues(navState, (value, key) => (Number(key.split("#")[1]) >= depth ? false : value)),
        [stateName]: !Boolean(navState[stateName]),
      });
    },
    [navState, setNavState],
  );

  const renderMenu = React.useCallback(
    (menu: IMenu, path: string, depth: number) => {
      const { link, title, Icon, subMenus } = menu;
      if (link) {
        return (
          <BasicLink key={title} to={link} disabled={location.pathname === link}>
            <ListItem
              button
              selected={location.pathname === link}
              onClick={() => setDrawerOpen(false)}
              style={{ paddingLeft: `${PADDING_MULTIPLIER * (depth + 1)}em` }}
            >
              <ListItemIcon>
                <Icon />
              </ListItemIcon>
              <ListItemText primary={title} />
            </ListItem>
          </BasicLink>
        );
      } else if (subMenus) {
        const stateName = `${path}/${title}#${depth}`;
        return (
          <div key={title}>
            <ListItem
              button
              onClick={() => toggleCollapse(stateName)}
              style={{ paddingLeft: `${PADDING_MULTIPLIER * (depth + 1)}em` }}
            >
              <ListItemIcon>
                <Icon />
              </ListItemIcon>
              <ListItemText primary={title} />
              {Boolean(navState[stateName]) ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </ListItem>

            <Collapse in={Boolean(navState[stateName])} timeout="auto">
              <List component="div" disablePadding>
                {menu.subMenus.map((subMenu) => renderMenu(subMenu, `${path}/${title}`, depth + 1))}
              </List>
            </Collapse>
          </div>
        );
      }
    },
    [location.pathname, navState, toggleCollapse, setDrawerOpen],
  );

  const navList = React.useMemo(() => {
    return _.map(menuList, (menu) => renderMenu(menu, "", 0));
  }, [renderMenu, menuList]);

  return <List style={{ padding: 0 }}>{navList}</List>;
}
