import { IMeta, IPagination, ISort } from "@delanobgt/admin-core/util/types";
import { produce } from "immer";
import { Reducer } from "redux";
import {
  ERetailWithdrawRequestSortField,
  IRetailWithdrawRequest,
  PRetailWithdrawRequestFilter,
} from "src/core/retailWithdrawRequest/entities";
import { EAuthRoles } from "src/core/auth/entities";
import { ActionType, createAction, getType } from "typesafe-actions";

/*
 * state
 */
export interface IRetailWithdrawRequestState {
  retailWithdrawRequests: IRetailWithdrawRequest[];
  loading: boolean;
  error: unknown;
  meta: IMeta;
}

const initialState: IRetailWithdrawRequestState = {
  retailWithdrawRequests: [],
  loading: false,
  error: null,
  meta: {
    limit: 10,
    offset: 0,
    total: 0,
  },
};

/*
 * action types
 */
const FETCH_REQUESTED = "retail-withdraw-request/FETCH_REQUESTED";
const FETCH_SUCCEEDED = "retail-withdraw-request/FETCH_SUCCEEDED";
const FETCH_FAILED = "retail-withdraw-request/FETCH_FAILED";
const POLLING_START_REQUESTED = "retail-withdraw-request/POLLING_START_REQUESTED";
const POLLING_STOP_REQUESTED = "retail-withdraw-request/POLLING_STOP_REQUESTED";

/*
 * action creators
 */
export const actions = {
  fetchRequested: createAction(FETCH_REQUESTED)(),

  fetchSucceeded: createAction(FETCH_SUCCEEDED)<{
    data: IRetailWithdrawRequest[];
    meta: IMeta;
  }>(),

  fetchFailed: createAction(FETCH_FAILED)<unknown>(),

  pollingStartRequested: createAction(POLLING_START_REQUESTED)<{
    role: EAuthRoles;
    filter: PRetailWithdrawRequestFilter;
    pagination: IPagination;
    sorts: ISort<ERetailWithdrawRequestSortField>[];
    refreshDelay: number;
  }>(),

  pollingStopRequested: createAction(POLLING_STOP_REQUESTED)(),
};
export type TActions = ActionType<typeof actions>;

const reducer: Reducer<IRetailWithdrawRequestState, TActions> = (state = initialState, action) => {
  return produce<IRetailWithdrawRequestState>(state, (draft) => {
    switch (action.type) {
      case getType(actions.fetchRequested): {
        draft.loading = true;
        draft.error = null;
        break;
      }
      case getType(actions.fetchSucceeded): {
        const {
          payload: { data, meta },
        } = action;
        draft.retailWithdrawRequests = data;
        draft.loading = false;
        draft.error = null;
        draft.meta = meta;
        break;
      }
      case getType(actions.fetchFailed): {
        const { payload } = action;
        draft.loading = false;
        draft.error = payload;
        break;
      }
    }
  });
};

export default reducer;
