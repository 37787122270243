import { IMeta, IPagination, ISort } from "@delanobgt/admin-core/util/types";
import { CancelTokenSource } from "axios";
import { EAuthRoles } from "src/core/auth/entities";

import { ERetailRevenueSortField, IRetailRevenue, PRetailRevenueFilter } from "../entities";
import IRetailRevenueRepository from "../repository";
import IRetailRevenueUsecase from "../usecase";

export default class RetailRevenueUsecase implements IRetailRevenueUsecase<CancelTokenSource> {
  private retailRevenueRepo: IRetailRevenueRepository<CancelTokenSource>;

  constructor(retailRevenueRepo: IRetailRevenueRepository<CancelTokenSource>) {
    this.retailRevenueRepo = retailRevenueRepo;
  }

  async getRetailRevenue(
    role: EAuthRoles,
    pagination: IPagination,
    filter: PRetailRevenueFilter,
    sorts: ISort<ERetailRevenueSortField>[],
    signal?: CancelTokenSource,
  ): Promise<[IRetailRevenue[], IMeta]> {
    return await this.retailRevenueRepo.getRetailRevenue(role, pagination, filter, sorts, signal);
  }
}
