import { produce } from "immer";
import { Reducer } from "redux";
import { IRetailMonthlyRevenue } from "src/core/retailStatistic/entities";
import { ActionType, createAction, getType } from "typesafe-actions";

/*
 * state
 */
export interface IRetailMonthlyRevenueState {
  monthlyRevenue: IRetailMonthlyRevenue;
  loading: boolean;
  error: unknown;
}

const initialState: IRetailMonthlyRevenueState = {
  monthlyRevenue: null,
  loading: true,
  error: null,
};

/*
 * action types
 */
const FETCH_REQUESTED = "retail-monthly-revenue/FETCH_REQUESTED";
const FETCH_SUCCEEDED = "retail-monthly-revenue/FETCH_SUCCEEDED";
const FETCH_FAILED = "retail-monthly-revenue/FETCH_FAILED";
const POLLING_START_REQUESTED = "retail-monthly-revenue/POLLING_START_REQUESTED";
const POLLING_STOP_REQUESTED = "retail-monthly-revenue/POLLING_STOP_REQUESTED";

/*
 * action creators
 */
export const actions = {
  fetchRequested: createAction(FETCH_REQUESTED)(),

  fetchSucceeded: createAction(FETCH_SUCCEEDED)<IRetailMonthlyRevenue>(),

  fetchFailed: createAction(FETCH_FAILED)<unknown>(),

  pollingStartRequested: createAction(POLLING_START_REQUESTED)<{
    refreshDelay: number;
    month: string;
    year: string;
  }>(),

  pollingStopRequested: createAction(POLLING_STOP_REQUESTED)(),
};
export type TActions = ActionType<typeof actions>;

const reducer: Reducer<IRetailMonthlyRevenueState, TActions> = (state = initialState, action) => {
  return produce<IRetailMonthlyRevenueState>(state, (draft) => {
    switch (action.type) {
      case getType(actions.fetchRequested): {
        draft.loading = true;
        draft.error = null;
        break;
      }
      case getType(actions.fetchSucceeded): {
        const { payload } = action;
        draft.monthlyRevenue = payload;
        draft.loading = false;
        draft.error = null;
        break;
      }
      case getType(actions.fetchFailed): {
        const { payload } = action;
        draft.loading = false;
        draft.error = payload;
        break;
      }
    }
  });
};

export default reducer;
